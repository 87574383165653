import i18n from 'i18next';

i18n.addResourceBundle('cs_cz-x-edri', 'translation', require('./cs-CZ-x-edri.json'));
i18n.addResourceBundle('cs_cz', 'translation', require('./cs-CZ.json'));
i18n.addResourceBundle('da_dk-x-edri', 'translation', require('./da-DK-x-edri.json'));
i18n.addResourceBundle('da_dk', 'translation', require('./da-DK.json'));
i18n.addResourceBundle('de_de-x-edri', 'translation', require('./de-DE-x-edri.json'));
i18n.addResourceBundle('de_de', 'translation', require('./de-DE.json'));
i18n.addResourceBundle('en_gb-x-edri', 'translation', require('./en-GB-x-edri.json'));
i18n.addResourceBundle('en_gb', 'translation', require('./en-GB.json'));
i18n.addResourceBundle('es_es', 'translation', require('./es-ES.json'));
i18n.addResourceBundle('fr_fr', 'translation', require('./fr-FR.json'));
i18n.addResourceBundle('hu_hu-x-edri', 'translation', require('./hu-HU-x-edri.json'));
i18n.addResourceBundle('hu_hu', 'translation', require('./hu-HU.json'));
i18n.addResourceBundle('it_it-x-edri', 'translation', require('./it-IT-x-edri.json'));
i18n.addResourceBundle('it_it', 'translation', require('./it-IT.json'));
i18n.addResourceBundle('nl_nl-x-edri', 'translation', require('./nl-NL-x-edri.json'));
i18n.addResourceBundle('nl_nl', 'translation', require('./nl-NL.json'));
i18n.addResourceBundle('pl_pl-x-edri', 'translation', require('./pl-PL-x-edri.json'));
i18n.addResourceBundle('pl_pl', 'translation', require('./pl-PL.json'));
i18n.addResourceBundle('pt_pt', 'translation', require('./pt-PT.json'));
i18n.addResourceBundle('ro_ro-x-edri', 'translation', require('./ro-RO-x-edri.json'));
i18n.addResourceBundle('ro_ro', 'translation', require('./ro-RO.json'));
i18n.addResourceBundle('sl_si', 'translation', require('./sl-SI.json'));
i18n.addResourceBundle('sv_se-x-edri', 'translation', require('./sv-SE-x-edri.json'));
i18n.addResourceBundle('sv_se', 'translation', require('./sv-SE.json'));
