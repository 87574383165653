import type React from 'react';
import { useEffect, useState } from 'react';

import { TIMER_INTERVAL } from '../../constants/intervals';
import { formatElapsedTime } from '../../helpers';
import { useAppTranslation } from '../../hooks/useAppTranslation';

interface ChargingTimeProps {
  startedAt: string;
}

const ChargingTime: React.FC<ChargingTimeProps> = ({ startedAt }) => {
  const { t } = useAppTranslation();
  const [timePassed, setTimePassed] = useState<number>(0);

  useEffect(() => {
    if (!startedAt) return;

    setTimePassed(new Date().getTime() - new Date(startedAt).getTime());
    const interval = setInterval(() => {
      setTimePassed(prevTimePassed => prevTimePassed + TIMER_INTERVAL);
    }, TIMER_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [startedAt]);

  if (!startedAt) return null;

  return `${t('app.session_summary.details.started.label')} ${formatElapsedTime(timePassed)} ${t('app.session_summary.details.ago.label')}`;
};

export default ChargingTime;
