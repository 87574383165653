import React from 'react';

import Separator from '../../common/Seperator';
import styles from '../styles.module.css';

type Detail = string | number | null;

interface DetailItemProps {
  title?: string;
  tariffInfo?: Detail[];
  translations: string[];
  separator?: boolean;
  showNullish?: boolean;
}

const DetailItem = ({ translations, tariffInfo, separator, showNullish }: DetailItemProps) => {
  const showRow = tariffInfo && tariffInfo.length > 0;

  return (
    <section className={styles.tariffCol}>
      {showRow &&
        tariffInfo.map((t: Detail, index: number) => {
          const extractedNumber = typeof t === 'string' && t.replace(/\D/g, '');
          if (Number(extractedNumber) > 0 || (typeof t === 'number' && t > 0) || showNullish) {
            return (
              <section key={t} className={styles.tariffRow}>
                <span>{translations[index]}</span>
                <span className={styles.bold}>{t}</span>
              </section>
            );
          } else {
            return null;
          }
        })}
      {separator && <Separator />}
    </section>
  );
};

export default DetailItem;
