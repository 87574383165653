import React from 'react';

import styles from './styles.module.css';
import { ReactComponent as AngleRighIcon } from '../../assets/fa-icons/fa-angle-right.svg';

interface TextButtonProps {
  label: string;
  onClick: () => void;
  color?: string;
}

const TextButton = ({ label, onClick, color }: TextButtonProps) => {
  return (
    <section className={styles.container} onClick={onClick}>
      <span className={`bold ${styles.label}`} style={{ color: color || 'var(--dark-blue)' }}>
        {label}
      </span>
      <span className={styles.icon}>
        <AngleRighIcon width={10} height={18} style={{ color: color || 'var(--dark-blue)' }} />
      </span>
    </section>
  );
};

export default TextButton;
