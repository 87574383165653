import type { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';
import Button, { ButtonType } from '../../components/Button';
import Header from '../../components/Header';
import Input from '../../components/Input';
import Logo from '../../components/Logo';
import { DisplayMode } from '../../constants/display-mode';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { handleAndMonitorError } from '../../helpers';
import { formatEvsePhysicalReference } from '../../helpers/charge-point-validate';
import { buildTenantUrl } from '../../helpers/routing';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useConfig from '../../hooks/useConfig';
import { Http } from '../../http';
import { ROUTE_NAMES } from '../../router/route-names';
import { LANGUAGE_KEY, LocalStorage } from '../../storage';

const ChargePointSearch = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { logoUrl } = useConfig();
  const { displayMode, searchModeTextHint } = useConfig();
  const [evsePhysicalReference, setEvsePhysicalReference] = useState('');
  const handlePhysicalReferenceChange = (value: string) => {
    // Trim the value before setting it in the state
    setEvsePhysicalReference(value.trim());
  };
  const [error, setError] = useState<string | null>();
  const { name, languages } = useConfig();

  //  check if a specific language is set in localStorage
  const selectedLanguage = LocalStorage.getItem(LANGUAGE_KEY);

  const path = window.location.pathname.split('/');
  const tenantIndex = path.findIndex(element => element === 'tenant');
  const tenantName = path[tenantIndex + 1];

  useEffect(() => {
    if (!selectedLanguage && DisplayMode.Search) {
      navigate(`../tenant/${tenantName}/${ROUTE_NAMES.chooseLanguage}`);
    }
  }, [selectedLanguage, navigate, tenantName]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onNext = (response: AxiosResponse<any, any>) => {
    if (displayMode === DisplayMode.ChargePoint) {
      navigate(
        buildTenantUrl({
          pathPrefix: '..',
          tenant: response.headers['tenant'],
          routeName: ROUTE_NAMES.chargePoint,
        })
      );
    } else {
      navigate(
        buildTenantUrl({
          pathPrefix: '..',
          tenant: response.headers['tenant'],
          chargePointId: response?.data?.data?.chargePointId,
          evseId: evsePhysicalReference,
          routeName: ROUTE_NAMES.selectEvse,
        })
      );
    }
  };

  /**
   * This endpoint throws UnprocessableEntity (422)
   * if displayMode === 'EVSE' || displayMode === 'SEARCH' and the evse is disabled
   * OR if displayMode === 'CHARGE_POINT' and there are no enabled evses
   * so we avoid going further in case there's no enabled EVSE available
   * @param evseId
   */
  const fetchChargePoint = async (evseId: string) => {
    await new Http().get(ENDPOINT_URL.chargePoint(evseId), name);
  };

  const fetchEvse = async (evseId: string) => {
    try {
      // We only need the response from the first request.
      // The second one is only to verify the availability of the evse against the display mode
      const [response] = await Promise.all([new Http().get(ENDPOINT_URL.evse(evseId), name), fetchChargePoint(evseId)]);

      onNext(response);
    } catch (e) {
      if (e?.response?.status === 422) {
        // TODO: add more descriptive error messages for disabled EVSEs:
        setError(t('app.search_charge_point.not.found.error'));
        handleAndMonitorError(
          `Evse disabled and can't be shown for display mode ${displayMode} ${ENDPOINT_URL.evse(evsePhysicalReference ?? '')}: ${e}`
        );
      } else {
        throw e;
      }
    }
  };

  const handleSearch = async () => {
    if (!evsePhysicalReference) {
      setError(t('app.search_charge_point.input.empty.value.error'));
      return;
    }

    try {
      await fetchEvse(evsePhysicalReference);
    } catch (e) {
      handleAndMonitorError(
        `Error while trying to retrieve charge point details from ${ENDPOINT_URL.evse(evsePhysicalReference ?? '')}: ${e}`
      );

      try {
        await fetchEvse(formatEvsePhysicalReference(evsePhysicalReference));
      } catch (nextError) {
        setError(t('app.search_charge_point.not.found.error'));
        handleAndMonitorError(
          `Error while trying to retrieve charge point details from ${ENDPOINT_URL.evse(evsePhysicalReference ?? '')}: ${nextError}`
        );
      }
    }
  };

  return (
    <section className="page-container">
      <Header
        showBackButton={!!languages?.length}
        label={t('app.search_charge_point.header')}
        onClick={() =>
          navigate(
            buildTenantUrl({
              pathPrefix: '..',
              tenant: tenantName,
              routeName: ROUTE_NAMES.chooseLanguage,
            })
          )
        }
      />
      {logoUrl && <Logo logoUrl={logoUrl} />}
      <section className={styles.container}>
        <Input
          placeholder={searchModeTextHint}
          label={t('app.search_charge_point.input.label')}
          type="text"
          error={error}
          setValue={handlePhysicalReferenceChange}
        />
        <Button label={t('app.search_charge_point.button')} type={ButtonType.primary} onClick={handleSearch} />
      </section>
    </section>
  );
};

export default ChargePointSearch;
