import React from 'react';

import styles from './styles.module.css';
import useConfig from '../../hooks/useConfig';

const BottomNavigation = () => {
  const { bottomNavigation } = useConfig();

  return (
    <section>
      <hr className={styles.line} />
      <section className={styles['bottom_navigation_links__flex-container']}>
        {bottomNavigation.map((link, index) => (
          <a
            href={link.href}
            key={index}
            target="_blank"
            rel="noreferrer"
            className={styles['bottom_navigation_links__flex-item']}
          >
            {link.title}
          </a>
        ))}
      </section>
    </section>
  );
};

export default BottomNavigation;
