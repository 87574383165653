import dayjs from 'dayjs';
import type { PricingData, SpecialDay, TariffData, TimePeriod } from 'src/models/evse';

/**
 * Sorts tariff data starting with the current time period,
 * followed by other times in 24h sequential order
 */
export const sortTariffDataByClosestTime = (tariffData: TariffData[]): TariffData[] => {
  if (!tariffData.length) return tariffData;

  const now = dayjs();
  const currentHour = now.hour();
  const currentMinutes = now.minute();
  const currentTimeInMinutes = currentHour * 60 + currentMinutes;

  // Convert time string to minutes since midnight
  const timeToMinutes = (timeStr: string): number => {
    const [hoursStr, minutesStr] = timeStr.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr || '0', 10);
    return hours * 60 + minutes;
  };

  // Create a copy of the data with start and end times converted to minutes for easier comparison
  const dataWithMinutes = tariffData.map(item => ({
    ...item,
    startTimeInMinutes: timeToMinutes(item.hour),
    endTimeInMinutes: timeToMinutes(item.hourEnd),
  }));

  // Find which period the current time falls into
  let currentPeriodIndex = -1;

  dataWithMinutes.forEach((item, index) => {
    const { startTimeInMinutes, endTimeInMinutes } = item;
    
    // Handle cases where period crosses midnight
    if (startTimeInMinutes > endTimeInMinutes) {
      // Period spans across midnight (e.g., 22:00 to 06:00)
      if (currentTimeInMinutes >= startTimeInMinutes || currentTimeInMinutes < endTimeInMinutes) {
        currentPeriodIndex = index;
      }
    } else {
      // Normal period within same day
      if (currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes < endTimeInMinutes) {
        currentPeriodIndex = index;
      }
    }
  });

  // If no period contains current time, fall back to closest start time
  if (currentPeriodIndex === -1) {
    let smallestDiff = Infinity;
    
    dataWithMinutes.forEach((item, index) => {
      const diff = Math.abs(item.startTimeInMinutes - currentTimeInMinutes);
      const wrappedDiff = Math.min(diff, 24 * 60 - diff); // Handle wrap-around at midnight
      
      if (wrappedDiff < smallestDiff) {
        smallestDiff = wrappedDiff;
        currentPeriodIndex = index;
      }
    });
  }

  // Sort all times in ascending order by start time
  dataWithMinutes.sort((a, b) => a.startTimeInMinutes - b.startTimeInMinutes);

  // Rotate the array so it starts with the current period
  const result = currentPeriodIndex >= 0 ?
    [...dataWithMinutes.slice(currentPeriodIndex), ...dataWithMinutes.slice(0, currentPeriodIndex)] :
    dataWithMinutes;

  // Remove the temporary time properties before returning
  const sortedData = result.map(({ startTimeInMinutes, endTimeInMinutes, ...rest }) => rest) as TariffData[];

  return sortedData;
};

/**
 * Transforms pricing data to tariff data format for chart display
 */
export const transformPricingDataToTariffData = (pricing: unknown): TariffData[] => {
  if (!pricing) {
    console.log('No pricing data');
    return [];
  }

  // Type assertion to use our interface
  const pricingData = pricing as PricingData;
  const { timePeriods, pricePeriods, flexibleMarkUpAsFixedPerKwh, daysWhenApplied } = pricingData;

  if (!flexibleMarkUpAsFixedPerKwh && (!timePeriods?.length || !pricePeriods)) {
    console.log('Invalid pricing data structure');
    return [];
  }

  // Check if tomorrow is a special day
  let specialDayForTomorrow: SpecialDay | undefined;
  if (daysWhenApplied?.length) {
    const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
    specialDayForTomorrow = daysWhenApplied.find(specialDay => specialDay.specialDates.includes(tomorrow));
  }

  if (timePeriods?.length && pricePeriods) {
    const { connectionFeePeriods, energyFeePeriods, durationFeePeriods, idleFeePeriods } = pricePeriods;

    // Map periods to tariff data
    const tariffData = timePeriods.map((period: TimePeriod, index: number) => ({
      hour: period.startTime,
      hourEnd: period.endTime,
      ...(durationFeePeriods?.fee ? { durationFee: durationFeePeriods?.fee[index] } : {}),
      ...(energyFeePeriods?.fee ? { energyFee: energyFeePeriods?.fee[index] } : {}),
      ...(connectionFeePeriods?.fee ? { connectionFee: connectionFeePeriods?.fee[index] } : {}),
      ...(idleFeePeriods?.fee ? { idleFee: idleFeePeriods?.fee[index] } : {}),
    }));
    // Apply special day pricing if tomorrow is a special day
    // Sort by closest time to current time first
    const sortedTariffData = sortTariffDataByClosestTime(tariffData);

    //TODO: Use the commented code for the special day pricing task
    // if (specialDayForTomorrow) {
    //   console.log('specialDayForTomorrow enter', specialDayForTomorrow);
    //   // Get the midnight hour (00:00)
    //   const midnightHourIndex = sortedTariffData.findIndex(item => item.hour === '00:00');

    //   if (midnightHourIndex !== -1) {
    //     const specialPricePeriods = specialDayForTomorrow.pricePeriods;

    //     // For all periods from midnight onwards, replace with special day pricing
    //     for (let i = midnightHourIndex; i < sortedTariffData.length; i++) {
    //       const periodIndex = i - midnightHourIndex;

    //       // Replace fee values if they exist in the special day
    //       if (
    //         specialPricePeriods.durationFeePeriods?.fee &&
    //         specialPricePeriods.durationFeePeriods.fee[periodIndex] !== undefined
    //       ) {
    //         sortedTariffData[i].durationFee = specialPricePeriods.durationFeePeriods.fee[periodIndex];
    //       }

    //       if (
    //         specialPricePeriods.energyFeePeriods?.fee &&
    //         specialPricePeriods.energyFeePeriods.fee[periodIndex] !== undefined
    //       ) {
    //         sortedTariffData[i].energyFee = specialPricePeriods.energyFeePeriods.fee[periodIndex];
    //       }

    //       if (
    //         specialPricePeriods.connectionFeePeriods?.fee &&
    //         specialPricePeriods.connectionFeePeriods.fee[periodIndex] !== undefined
    //       ) {
    //         sortedTariffData[i].connectionFee = specialPricePeriods.connectionFeePeriods.fee[periodIndex];
    //       }

    //       if (
    //         specialPricePeriods.idleFeePeriods?.fee &&
    //         specialPricePeriods.idleFeePeriods.fee[periodIndex] !== undefined
    //       ) {
    //         sortedTariffData[i].idleFee = specialPricePeriods.idleFeePeriods.fee[periodIndex];
    //       }
    //     }
    //   }
    // }

    return sortedTariffData;
  } else if (flexibleMarkUpAsFixedPerKwh) {
    const tariffData = flexibleMarkUpAsFixedPerKwh.intervalPricing.map(period => ({
      hour: period.startsAt,
      hourEnd: period.endsAt,
      price: period.price,
    }));

    // Sort by closest time to current time
    return sortTariffDataByClosestTime(tariffData);
  }

  return [];
};

/**
 * Calculates appropriate Y-axis tick values based on the data
 * @param data - The tariff data to analyze
 * @param tickCount - The desired number of ticks (default: 6)
 * @returns An array of tick values
 */
export const getMaxYAxisValue = (data: TariffData[]): number => {
  if (!data.length) return 0;

  // Extract all numeric values from the data
  const allValues: number[] = [];
  data.forEach(item => {
    if (item.durationFee !== undefined) allValues.push(item.durationFee);
    if (item.energyFee !== undefined) allValues.push(item.energyFee);
    if (item.connectionFee !== undefined) allValues.push(item.connectionFee);
    if (item.idleFee !== undefined) allValues.push(item.idleFee);
    if (item.price !== undefined) allValues.push(item.price);
  });

  // Find the maximum value in the data
  const maxValue = Math.max(...allValues);

  return maxValue;
};

/**
 * Creates a function to determine the fill color for bars based on position
 */
export const createGetFillColor = (middleIndex: number, grayColor: string, originalColor: string) => {
  return (_entry: unknown, index: number) => {
    return originalColor; //index >= middleIndex ? grayColor : originalColor;
  };
};
