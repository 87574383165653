import type { Config } from '../../models/config';
import { CONFIG_KEY, LocalStorage } from '../../storage';

const useConfig = () => {
  const config = LocalStorage.getItem(CONFIG_KEY) as Config | undefined;

  if (!config) {
    throw new Error('Configuration is missing!');
  }

  return {
    name: config.name ?? '',
    displayName: config.displayName ?? '',
    logoUrl: config.logoUrl ?? '',
    colorPrimary: config.colorPrimary ?? '',
    colorSecondary: config.colorSecondary ?? '',
    paymentProvider: config.paymentProvider ?? '',
    merchantId: config.merchantId ?? '',
    searchModeTextHint: config.searchModeTextHint ?? '',
    stripeKey: config.stripeKey ?? '',
    stripeSecret: config.stripeSecret ?? '',
    worldLineGroupCards: config.worldLineGroupCards ?? false,
    displayMode: config.displayMode ?? '',
    termsAndConditionsUrl: config.termsAndConditionsUrl ?? '',
    termsAndConditionsChecked: config.termsAndConditionsUrl ? (config.termsAndConditionsChecked ?? true) : true,
    contactPhoneNumber: config.contactPhoneNumber ?? '',
    receiptsUrl: config.receiptsUrl ?? '',
    bottomNavigation: config.bottomNavigation ?? [],
    languages: config.languages ?? [],
    localizeSuffix: config.localizeSuffix,
    appStoreUrl: config.appStoreUrl ?? '',
    googleStoreUrl: config.googleStoreUrl ?? '',
  };
};

export default useConfig;
