import type { PropsWithChildren } from 'react';
import React from 'react';

import styles from './styles.module.css';

interface CardProps {
  className?: string;
}

const Card = ({ children, className }: PropsWithChildren<CardProps>) => {
  return <section className={`blue-card ${styles.card} ${className}`}>{children}</section>;
};

export default Card;
