import type { SessionStatus } from '../models/session';

export const FA_ICONS = {
  faAngleRight: '/assets/fa-icons/fa-angle-right.svg',
  faAngleRightBlue: '/assets/fa-icons/fa-angle-right-blue.svg',
  faArrowLeft: '/assets/fa-icons/fa-arrow-left.svg',
  faCircleCheck: '/assets/fa-icons/fa-circle-check.svg',
  faCircleCheckLarge: '/assets/fa-icons/fa-circle-check-large.svg',
  faCircleInfo: '/assets/fa-icons/fa-circle-info.svg',
  faCircleXmark: '/assets/fa-icons/fa-circle-xmark.svg',
  faLocationDot: '/assets/fa-icons/fa-location-dot.svg',
  faLocation: '/assets/fa-icons/fa-location.svg',
  faXmark: '/assets/fa-icons/fa-xmark.svg',
  faAppleLogo: '/assets/fa-icons/fa-apple-logo.svg',
  faBatteryFullSolid: '/assets/fa-icons/fa-battery-full-solid.svg',
  faBatteryHalfSolid: '/assets/fa-icons/fa-battery-half-solid.svg',
  faBatteryQuarterSolid: '/assets/fa-icons/fa-battery-quarter-solid.svg',
  faBatteryThreeQuarterSolid: '/assets/fa-icons/fa-battery-three-quarters-solid.svg',
  faGooglePlayLogo: '/assets/fa-icons/fa-google-play-logo.svg',
  faLightMedal: '/assets/fa-icons/fa-light-medal.svg',
  faCircle: '/assets/fa-icons/fa-circle.svg',
  faCircleEntered: '/assets/fa-icons/fa-circle-entered.svg',
  faCharge: '/assets/fa-icons/fa-charge.svg',
  faChargePreparing: '/assets/fa-icons/fa-charge-preparing.svg',
  faClock: '/assets/fa-icons/fa-clock.svg',
  faCoins: '/assets/fa-icons/fa-coins.svg',
  faClose: '/assets/fa-icons/fa-close.svg',
  faChargingBattery: '/assets/fa-icons/fa-charging-battery.svg',
  faBriefcase: '/assets/fa-icons/fa-briefcase.svg',
  faCircleDot: '/assets/fa-icons/fa-circle-dot.svg',
  faMaskedInput: '/assets/fa-icons/fa-masked-input.svg',
  faTrinagleExclamation: '/assets/fa-icons/fa-triangle-exclamation.svg',
  faCall: '/assets/fa-icons/fa-call.svg',
  faSun: '/assets/fa-icons/fa-sun.svg',
  faMoon: '/assets/fa-icons/fa-moon.svg',
  faBackspace: '/assets/fa-icons/fa-backspace.svg',
};

export const GIF = {
  battery: '/assets/gif/battery.gif',
  waveLoader: '/assets/gif/wave-loader.gif',
  waveLoaderLarge: '/assets/gif/wave-loader-large.gif',
  waveLoaderWhite: '/assets/gif/wave-loader-white.gif',
};

export const PAYMENT_METHODS_ICONS = {
  applePay: '/assets/payment-methods/apple-pay.svg',
  googlePay: '/assets/payment-methods/google-pay.svg',
  mastercard: '/assets/payment-methods/mastercard.svg',
  visa: '/assets/payment-methods/visa.svg',
};

export const CHART_COLORS = {
  DURATION_FEE: '#2196F3',
  ENERGY_FEE: '#4CAF50',
  CONNECTION_FEE: '#c45eba',
  IDLE_FEE: '#FFA726',
  SPECIAL_FEE: '#bbb',
  GRID: '#e0e0e0',
};

export const SESSION_STATUS_ICONS: Record<
  Exclude<SessionStatus, SessionStatus.finished | SessionStatus.active | SessionStatus.pending>,
  string
> = {
  expired: '/assets/session/session-status-expired.svg',
  unknown: '/assets/session/session-status-unknown.svg',
  failed: '/assets/session/session-status-failed.svg',
};
