import type { ChargePoint } from './chargePoint';
import type { ConnectorType } from './evse';

export enum SessionStatus {
  'unknown' = 'unknown',
  'pending' = 'pending',
  'active' = 'active',
  'finished' = 'finished',
  'failed' = 'failed',
  'expired' = 'expired',
}

export interface Session {
  id: string;
  status: SessionStatus;
  startedAt: Date | string | null;
  stoppedAt: Date | string | null;
  powerKw: number;
  amount: number;
  currency: string;
  energy: number;
}

export interface SessionResponseData {
  data: {
    chargePoint: ChargePoint;
    session: Session;
  };
}

export interface SessionSummary {
  energy: number;
  amount: number;
  currency: string;
  name: string;
  address: string;
  connectorType: ConnectorType;
  duration: string;
  status: SessionStatus;
  billingStatus: SessionBillingStatus;
  receiptId: number | null;
  startedAt: string;
  stoppedAt: string;
  startedAtLocal?: string;
  stoppedAtLocal?: string;
  dateTimeFormat?: string;
}

export enum SessionBillingStatus {
  'completed' = 'completed',
  'suspended' = 'suspended',
  'pending' = 'pending',
}
