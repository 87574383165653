import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader';
import PinCode from '../../components/PinCode';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { checkPinCode, handleAndMonitorError } from '../../helpers';
import { checkPathnameIncludes } from '../../helpers/routing';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useConfig from '../../hooks/useConfig';
import useSession from '../../hooks/useSession';
import { Http } from '../../http';
import type { Payment } from '../../models/payment';
import { ROUTE_NAMES } from '../../router/route-names';
import { LocalStorage, PAYMENT_KEY } from '../../storage';

const CreatePinCode = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [pinCode, setPinCode] = useState<string>();
  const [showRepeatPinCode, setShowRepeatPinCode] = useState(false);
  const location = useLocation();
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;
  const [loading, setLoading] = useState(false);
  const { name } = useConfig();
  const { startSession } = useSession();

  useEffect(() => {
    if (checkPathnameIncludes(ROUTE_NAMES.resetPinCode) && !location?.state?.last4) {
      navigate(`../${ROUTE_NAMES.reEnterCardDetails}`);
      return;
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handleSetPin = (pin: string) => {
    const validationResult = checkPinCode(pin, t);

    if (validationResult.isInvalid) {
      setError(validationResult.errorMessage);
      return;
    }

    setPinCode(pin);
    setError(null);
    setShowRepeatPinCode(true);
  };

  const isStartSessionFlow = (): boolean => {
    return !checkPathnameIncludes(ROUTE_NAMES.resetPinCode);
  };

  const handleRepeatPinValidation = (repeatedPin: string) => {
    if (!payment) {
      throw new Error('Payment details are missing');
    }

    if (pinCode !== repeatedPin) {
      setError(t('app.repeat_pin_code.error.message'));
      return;
    }

    setLoading(true);
    handleRepeatPin(payment, pinCode).then();
  };

  const handleRepeatPin = async (paymentDetails: Payment, pin: string) => {
    try {
      const details = location?.state;
      let sessionId;

      if (isStartSessionFlow()) {
        const response = await startSession({
          chargePointId: paymentDetails.chargePointId,
          evseId: paymentDetails.evseId,
          pin: pin,
          paymentIntentId: details?.paymentIntentId ?? payment?.paymentIntentId,
          depositAmount: paymentDetails.amount,
          currency: paymentDetails.currency,
          connectorId: paymentDetails.connectorId,
        });

        sessionId = response?.data?.sessionId;
      } else {
        await new Http().patch(
          ENDPOINT_URL.updateNewPin(paymentDetails.chargePointId, paymentDetails.evseId),
          {
            pin: pin,
            last4: details?.last4,
            expYear: details?.expYear,
            expMonth: details?.expMonth,
          },
          name
        );

        sessionId = payment?.sessionId;
      }

      if (payment) {
        const updatedPayment = {
          ...payment,
          sessionId: sessionId,
        };
        LocalStorage.setItem(PAYMENT_KEY, updatedPayment);
      }
      navigate(`../${ROUTE_NAMES.chargingSession}/${sessionId}`, {
        state: {
          sessionId,
          pin,
        },
        replace: true,
      });
    } catch (e) {
      let errorMessage;

      if (isStartSessionFlow()) {
        errorMessage = t('app.create_pin_code.error.message.cannot.start.session');
        handleAndMonitorError(
          `Error while trying to start a session from ${ENDPOINT_URL.startSession(
            paymentDetails.chargePointId,
            paymentDetails.evseId
          )}: ${e}`
        );
      } else {
        errorMessage = t('app.re_enter_card_details.new.pin.general.error');
        handleAndMonitorError(
          `Error while trying to save new pin code from ${ENDPOINT_URL.updateNewPin(
            paymentDetails.chargePointId,
            paymentDetails.evseId
          )}: ${e}`
        );
      }

      setLoading(false);
      setError(errorMessage);
    }
  };

  const handleHeaderLabel = (): string => {
    if (showRepeatPinCode) {
      return t('app.repeat_pin_code.header');
    }

    if (checkPathnameIncludes(ROUTE_NAMES.resetPinCode)) {
      return t('app.reset_pin_code.header');
    }

    return t('app.create_pin_code.header');
  };

  return (
    <section className="page-container">
      <PinCode
        showBackButton={false}
        headerLabel={handleHeaderLabel()}
        buttonLabel={t('app.create_pin_code.set.pin.button')}
        onForwardClick={showRepeatPinCode ? handleRepeatPinValidation : handleSetPin}
        error={error}
      />
    </section>
  );
};

export default CreatePinCode;
