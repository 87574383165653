export function formatEvsePhysicalReference(evsePhysicalReference: string) {
  const evseEMI3Regex = /^([A-Z]{2})[*\-]?([A-Z0-9]{3})[*\-]?([E][A-Z0-9\*]{1,30})$/;
  const match = evsePhysicalReference?.match(evseEMI3Regex);

  if (!match) return evsePhysicalReference;

  const evsePhysicalReferenceEMI3 = match && match[3]?.substring(1);

  return evsePhysicalReferenceEMI3;
}
