import React from 'react';

import { TariffType } from '../../../models/evse';
import type { DetailsSectionProps } from '../../../models/evse';
import {
  DurationEnergyTariff,
  DurationEnergyTODTariff,
  EnergyTOUTariff,
  StandardTariff,
  StandardTODTariff,
} from '../../Tariffs';

type TariffSelectorProps = Omit<DetailsSectionProps, 'tenant' | 'chargePointId' | 'noShowFixedTariff'> & {
  noShowFixedTariff?: boolean;
};

const TariffSelector = (props: TariffSelectorProps) => {
  switch (props.tariff?.type) {
    case TariffType.DURATION_ENERGY:
      return <DurationEnergyTariff {...props} />;
    case TariffType.DURATION_ENERGY_TOD:
      return <DurationEnergyTODTariff {...props} />;
    case TariffType.STANDARD_TOD:
      return <StandardTODTariff {...props} />;
    case TariffType.ENERGY_TOU:
      return <EnergyTOUTariff {...props} />;
    case TariffType.STANDARD:
      return <StandardTariff {...props} />;
    default: {
      return null;
    }
  }
};

export default TariffSelector;
