import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';
import TariffSelector from './TariffSelector';
import { FA_ICONS } from '../../../constants';
import { isEvseAvailable } from '../../../helpers/evse-statuses';
import { formatPaymentLangCode } from '../../../helpers/language';
import { buildTenantUrl } from '../../../helpers/routing';
import { useAppTranslation } from '../../../hooks/useAppTranslation';
import useConfig from '../../../hooks/useConfig';
import usePayment from '../../../hooks/usePayment';
import { type DetailsSectionProps, EvseStatus, TariffType } from '../../../models/evse';
import type { Payment } from '../../../models/payment';
import { ROUTE_NAMES } from '../../../router/route-names';
import { LANGUAGE_KEY, LocalStorage, PAYMENT_KEY } from '../../../storage';
import Button, { ButtonType } from '../../Button';
import Image from '../../Image';
import TextButton from '../../TextButton';

const DetailsSection = ({ tenant, chargePointId, activeSession, sessionId, ...evseProps }: DetailsSectionProps) => {
  const {
    status,
    hardwareStatus,
    id: evseId,
    physicalReference: evsePhysicalReference,
    connectors,
    tariff,
  } = evseProps;

  const connectorId = connectors[0]?.id;

  const {
    description,
    additionalInformation: additionalInfo,
    currency,
    restrictions: { adHocPreAuthorizeAmount },
  } = tariff;

  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { termsAndConditionsUrl, termsAndConditionsChecked } = useConfig();
  const [checked, setChecked] = useState(termsAndConditionsChecked);
  const [error, setError] = useState<string | null>();
  const { paymentProvider, colorPrimary } = useConfig();
  const { createPayment } = usePayment();
  const storedLanguage = LocalStorage.getItem(LANGUAGE_KEY) as string | undefined;
  const languageTag = formatPaymentLangCode(storedLanguage);
  const language = storedLanguage?.split('_')[0];
  const hideInfo = (additionalInfo && !additionalInfo[language as string]) || !additionalInfo;

  const updatePaymentDetails = () => {
    const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment;

    const updatedPayment: Payment = {
      ...payment,
      amount: adHocPreAuthorizeAmount,
      currency,
      chargePointId,
      evseId,
      evsePhysicalReference,
      connectorId,
      sessionId,
    };

    LocalStorage.setItem(PAYMENT_KEY, updatedPayment);
  };

  const navigateToPriceDetails = () => {
    if (hideInfo) return;

    navigate(`../${ROUTE_NAMES.priceDetails}`, { state: { description: additionalInfo } });
  };

  const navigateToPayment = async () => {
    updatePaymentDetails();

    if (!checked) {
      setError(t('app.select_evse.terms_and_conditions.unchecked.error'));
      return;
    }

    if (paymentProvider === 'worldline') {
      const slicePoint = window.location.href.indexOf('/evses');
      const newUrl = window.location.href.slice(0, slicePoint);
      const returnUrl = `${newUrl}/confirm-payment`;

      const createPaymentIntentResponse = await createPayment(
        chargePointId,
        evseId,
        adHocPreAuthorizeAmount,
        currency,
        languageTag,
        returnUrl
      );
      const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;

      if (createPaymentIntentResponse.status !== 200) setError(t('app.payment_method.confirm.payment.error'));

      if (payment) {
        const updatedPayment = {
          ...payment,
          paymentIntentId: createPaymentIntentResponse.data?.responseBody?.hostedCheckoutId,
        };

        LocalStorage.setItem(PAYMENT_KEY, updatedPayment);

        window.location.replace(createPaymentIntentResponse.data?.responseBody?.redirectUrl);
      }
    } else if (paymentProvider === 'stripe') {
      navigate(
        buildTenantUrl({
          pathPrefix: '..',
          tenant: tenant ?? '',
          chargePointId: chargePointId,
          evseId: evsePhysicalReference,
          routeName: ROUTE_NAMES.choosePaymentMethod,
        }),
        { replace: true }
      );
    }
  };

  const navigateToEnterPin = () => {
    updatePaymentDetails();

    if (!checked) {
      setError(t('app.select_evse.terms_and_conditions.unchecked.error'));
      return;
    }

    navigate(
      buildTenantUrl({
        pathPrefix: '..',
        tenant: tenant,
        chargePointId: chargePointId,
        evseId: evsePhysicalReference,
        routeName: ROUTE_NAMES.enterPinCode,
      })
    );
  };

  const navigateToPriceForecast = () => {
    navigate(
      buildTenantUrl({
        pathPrefix: '..',
        tenant: tenant,
        chargePointId: chargePointId,
        evseId: evsePhysicalReference,
        routeName: ROUTE_NAMES.priceForecast,
      }),
      { state: { evseProps } }
    );
  };

  const handlePayNow = () => {
    if (isEvseOutOfOrder) {
      return;
    }
    if (isEvseAvailable(hardwareStatus || '')) {
      navigateToPayment();
    } else {
      navigateToEnterPin();
    }
  };
  const isEvseOutOfOrder = status === EvseStatus.outOfOrder;

  const isPayButtonDisabled = isEvseOutOfOrder || !!activeSession;

  const isForecastVisible = useMemo(() => {
    //NOTE: Add more tariffs here as they become available
    return (
      (tariff.type === TariffType.ENERGY_TOU ||
        tariff.type === TariffType.STANDARD ||
        tariff.type === TariffType.STANDARD_TOD) &&
      tariff.pricing.timePeriods?.length > 1
    );
  }, [tariff.type]);

  return (
    <section className={styles.container}>
      <section className={styles.header}>
        <section className={styles.details}>
          <span className="bold" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {t('app.select_evse.details.header')}
            {!hideInfo && (
              <Image className={styles.infoIcon} src={FA_ICONS.faCircleInfo} onClick={navigateToPriceDetails} />
            )}
          </span>
          {isForecastVisible && (
            <TextButton
              label={t('app.select_evse.details.price.prognosis')}
              color={colorPrimary}
              onClick={navigateToPriceForecast}
            />
          )}
        </section>
        {description ? <p dangerouslySetInnerHTML={{ __html: description[language ?? 'en'] ?? '' }} /> : null}
      </section>
      <section className={styles.tariff}>
        <TariffSelector {...evseProps} />
      </section>
      <section className={styles.tariffAction}>
        {activeSession ? (
          <Button
            label={t('app.select_evse.details.view.session.button')}
            type={ButtonType.primary}
            onClick={handlePayNow}
            icon={FA_ICONS.faAngleRight}
          />
        ) : (
          <Button
            disabled={isPayButtonDisabled}
            label={t('app.select_evse.details.pay.now.button')}
            type={ButtonType.primary}
            onClick={handlePayNow}
            icon={FA_ICONS.faAngleRight}
          />
        )}
        {isEvseOutOfOrder && (
          <span className={styles.outOfOrderMessage}>{t('app.select_evse.details.pay_now.out_of_order')}</span>
        )}
      </section>

      {termsAndConditionsUrl && !isEvseOutOfOrder && (
        <section className={styles.terms}>
          <input
            type="checkbox"
            checked={checked}
            className={styles.termsInput}
            onChange={() => {
              setChecked(currentState => !currentState);
            }}
          />
          <section className={styles.termsDescription}>
            <span
              onClick={() => {
                setChecked(currentState => !currentState);
              }}
            >
              {t('app.select_evse.details.terms.and.conditions.label')}{' '}
            </span>

            <a href={termsAndConditionsUrl} target="_blank" className={styles.termsUrl} rel="noreferrer">
              {t('app.select_evse.details.terms.and.conditions.url')}
            </a>
          </section>
        </section>
      )}

      {error && <section className="general-error">{error}</section>}
    </section>
  );
};

export default DetailsSection;
