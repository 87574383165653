import type { FC } from 'react';
import React from 'react';

import DetailItem from './DetailItem';
import styles from './styles.module.css';
import { decapitalizeFirstLetter, displayData } from '../../helpers';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import type { DetailsSectionProps } from '../../models/evse';

type TariffSelectorProps = Omit<DetailsSectionProps, 'tenant' | 'chargePointId'>;

const DurationEnergyTODTariff: FC<TariffSelectorProps> = props => {
  const { t } = useAppTranslation();
  const {
    tariff: {
      pricing: {
        connectionFee,
        connectionFeeMinimumSessionDuration,
        connectionFeeMinimumSessionEnergy,
        pricePeriodInMinutes,
        durationFeeGracePeriod,
        idleFeeGracePeriodMinutes,
        minPrice,
        dayPricePerKwh,
        dayPricePerPeriod,
        dayIdleFeePerMinute,
        nightIdleFeePerMinute,
        nightPricePerPeriod,
        nightPricePerKwh,
      },
      restrictions: { adHocPreAuthorizeAmount: deposit },
      currency,
      dayTariffStart,
      nightTariffStart,
    },
  } = props;

  return (
    <>
      <section className={styles.dayNightTariff}>
        {deposit && deposit > 0 ? (
          <DetailItem
            separator
            translations={[t('app.tariff.pre_auth_amount')]}
            tariffInfo={[displayData(deposit, currency)]}
          />
        ) : null}
        {dayPricePerKwh && dayPricePerKwh > 0 ? (
          <DetailItem
            translations={[
              `(${dayTariffStart?.substring(0, 5)}-${nightTariffStart?.substring(0, 5)})`,
              `${t('app.tariff.day_starts_at')} (${decapitalizeFirstLetter(t('app.tariff.price_per_kwh'))})`,
            ]}
            tariffInfo={[null, displayData(dayPricePerKwh, currency)]}
          />
        ) : null}
        {nightPricePerKwh && nightPricePerKwh > 0 ? (
          <DetailItem
            translations={[
              `(${nightTariffStart?.substring(0, 5)}-${dayTariffStart?.substring(0, 5)})`,
              `${t('app.tariff.night_starts_at')} (${decapitalizeFirstLetter(t('app.tariff.price_per_kwh'))})`,
            ]}
            tariffInfo={[null, displayData(nightPricePerKwh, currency)]}
          />
        ) : null}
      </section>
      {minPrice ? (
        <DetailItem
          separator
          translations={[t('app.tariff.min_price')]}
          tariffInfo={[displayData(minPrice, currency)]}
        />
      ) : null}
      {connectionFee && connectionFee > 0 ? (
        <DetailItem
          separator
          translations={[
            t('app.tariff.connection_fee'),
            t('app.tariff.connection_fee_min_session_duration'),
            t('app.tariff.connection_fee_min_session_energy'),
          ]}
          tariffInfo={[
            displayData(connectionFee, currency),
            `${connectionFeeMinimumSessionDuration} mins`,
            `${connectionFeeMinimumSessionEnergy} kWh`,
          ]}
        />
      ) : null}
      {dayPricePerPeriod || nightPricePerPeriod ? (
        <DetailItem
          showNullish
          separator
          translations={[
            t('app.tariff.day_fee_per_time_period'),
            t('app.tariff.night_fee_per_time_period'),
            t('app.tariff.duration_fee_time_period'),
            t('app.tariff.duration_fee_grace_period'),
          ]}
          // If values are undefined, translations will also not render
          tariffInfo={[
            displayData(dayPricePerPeriod, currency),
            displayData(nightPricePerPeriod, currency),
            `${pricePeriodInMinutes} min`,
            `${durationFeeGracePeriod} min`,
          ]}
        />
      ) : null}
      {dayIdleFeePerMinute || nightIdleFeePerMinute ? (
        <DetailItem
          showNullish
          translations={[
            t('app.tariff.day_idle_fee_per_minute'),
            t('app.tariff.night_idle_fee_per_minute'),
            t('app.tariff.idle_fee_grace_period'),
          ]}
          tariffInfo={[
            displayData(dayIdleFeePerMinute, currency),
            displayData(nightIdleFeePerMinute, currency),
            `${idleFeeGracePeriodMinutes} min`,
          ]}
        />
      ) : null}
    </>
  );
};

export default DurationEnergyTODTariff;
