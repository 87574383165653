import React from 'react';
import type { PropsWithChildren } from 'react';

import styles from './styles.module.css';

const Modal = ({ children }: PropsWithChildren) => {
  return (
    <section className={styles.modal}>
      <section className={styles.content}>{children}</section>
    </section>
  );
};

export default Modal;
