import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BottomNavigation from '../../components/BottomNavigation/BottomNavigation';
import ChargePointContainer from '../../components/ChargePointContainer';
import DownloadApps from '../../components/DownloadApps';
import Evse from '../../components/Evse';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Logo from '../../components/Logo';
import ReceiptsUrl from '../../components/ReceiptsUrl/ReceiptsUrl';
import { DisplayMode } from '../../constants/display-mode';
import { buildTenantUrl } from '../../helpers/routing';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useChargePointData from '../../hooks/useChargePointData';
import useConfig from '../../hooks/useConfig';
import type { EvseModel as EvseModel } from '../../models/evse';
import { EvseStatus, HardwareStatus } from '../../models/evse';
import { ROUTE_NAMES } from '../../router/route-names';
import { LANGUAGE_KEY, LocalStorage } from '../../storage';

const SelectEvse = () => {
  const { t } = useAppTranslation();
  const { chargePoint, evses, tenant, availableCount, error } = useChargePointData();
  const { appStoreUrl, receiptsUrl, bottomNavigation, googleStoreUrl, logoUrl, displayMode, languages } = useConfig();
  const navigate = useNavigate();

  const urlPath = window.location.pathname.split('/');
  const evseIndex = urlPath.findIndex(element => element === 'evse');
  const evsePhysicalReference = urlPath[evseIndex + 1];
  const cpIndex = urlPath.findIndex(element => element === 'cp');
  const cpReference = urlPath[cpIndex + 1];
  const tenantIndex = urlPath.findIndex(element => element === 'tenant');
  const tenantName = urlPath[tenantIndex + 1];

  const missingLanguageRedirectUrl = buildTenantUrl({
    pathPrefix: '..',
    tenant: tenantName,
    routeName: ROUTE_NAMES.chooseLanguage,
  });

  useEffect(() => {
    const selectedLanguage = LocalStorage.getItem(LANGUAGE_KEY);
    if (!selectedLanguage && DisplayMode.Search) {
      navigate(missingLanguageRedirectUrl, {
        state: {
          goBackToSelectEvseURL: buildTenantUrl({
            pathPrefix: '..',
            tenant: tenantName,
            chargePointId: cpReference,
            evseId: evsePhysicalReference,
            routeName: ROUTE_NAMES.selectEvse,
          }),
        },
        replace: true,
      });
    }
  }, [cpReference, evsePhysicalReference, missingLanguageRedirectUrl, navigate, tenant, tenantName]);

  const startSessionIndex = window.location.pathname
    .split('/')
    .findIndex(element => element === ROUTE_NAMES.sessionStart);

  if (!chargePoint || !evses) {
    if (!error) {
      return <Loader />;
    }

    return (
      <section className="page-container">
        <section className="general-error">{t('app.charge_point.missing.details.error')}</section>
      </section>
    );
  }

  const handleBackClick = () => {
    let path = window.location.pathname.split('/cp')[0];

    if (displayMode === DisplayMode.Search) {
      if (startSessionIndex > 0) {
        path += `/${ROUTE_NAMES.chooseLanguage}`;
      } else {
        path += `/${ROUTE_NAMES.search}`;
      }
    } else if (displayMode === DisplayMode.ChargePoint) {
      path += `/${ROUTE_NAMES.chargePoint}`;
    } else {
      path += `/${ROUTE_NAMES.chooseLanguage}`;
    }

    navigate(path);
  };

  return (
    <section className="page-container">
      <Header
        showBackButton={languages && languages?.length ? true : displayMode !== DisplayMode.Evse ? true : false}
        label={t('app.select_evse.header')}
        onClick={handleBackClick}
      />
      {logoUrl && <Logo logoUrl={logoUrl} />}
      {displayMode === DisplayMode.ChargePoint && (
        <ChargePointContainer
          locationName={chargePoint.locationName}
          address={chargePoint.address}
          numberOfEvses={availableCount}
          hardwareStatus={HardwareStatus.available}
          status={EvseStatus.enabled}
        />
      )}

      {!evses?.length && (
        <section className="general-error">{t('app.select_evse.missing.available.evses.error')}</section>
      )}

      {evses.map((evse: EvseModel) => (
        <Evse
          key={evse.id}
          evse={evse}
          chargePointId={chargePoint.id}
          numberOfEvses={evses.length}
          tenant={tenant}
          locationName={chargePoint.locationName}
          address={chargePoint.address}
        />
      ))}

      {evses.length === 1 && (!!appStoreUrl || !!googleStoreUrl) && (
        <DownloadApps appStoreUrl={appStoreUrl} googleStoreUrl={googleStoreUrl} />
      )}

      {receiptsUrl && <ReceiptsUrl />}
      {bottomNavigation && <BottomNavigation />}
    </section>
  );
};

export default SelectEvse;
