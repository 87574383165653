import React, { useStripe } from '@stripe/react-stripe-js';
import type { StripeElements } from '@stripe/stripe-js';
import type { ComponentProps } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { initPaymentElement } from './config';
import styles from './styles.module.css';
import Button, { ButtonType } from '../../../components/Button';
import { FA_ICONS, GIF } from '../../../constants';
import { ENDPOINT_URL } from '../../../constants/endpoints';
import { handleAndMonitorError } from '../../../helpers';
import { useAppTranslation } from '../../../hooks/useAppTranslation';
import usePayment from '../../../hooks/usePayment/index';
import useSession from '../../../hooks/useSession';
import { StopSessionMode } from '../../../models/config';
import type { Payment } from '../../../models/payment';
import { SessionStatus } from '../../../models/session';
import { ROUTE_NAMES } from '../../../router/route-names';
import { LocalStorage, PAYMENT_KEY } from '../../../storage';

const Stripe = () => {
  const { t } = useAppTranslation();
  const stripe = useStripe();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [elements, setElements] = useState<StripeElements>();
  const [processingPayment, setProcessingPayment] = useState(false);
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;
  const { createPayment, createStripeFailedTransaction, getPayment } = usePayment();
  const { startSession } = useSession();
  const [disableStopButton, setDisableStopButton] = useState(false);

  useEffect(() => {
    if (!stripe || !payment) {
      return;
    }

    initPaymentElement(stripe, payment, setElements);
  }, [stripe]);

  const handleError = (error, message: string) => {
    console.log('Stripe Error ', error);
    setErrorMessage(message);
    setProcessingPayment(false);
  };

  const handleSubmit: ComponentProps<typeof Button>['onClick'] = async event => {
    event.preventDefault();

    if (!payment?.amount || !payment?.currency) {
      throw new Error('Amount and/or Currency are missing');
    }
    const submit = await elements?.submit();

    if (submit && submit.error) {
      handleError(submit.error, t('app.payment_method.card.details.error'));
      return;
    }

    setDisableStopButton(true);
    setProcessingPayment(true);
    setErrorMessage(undefined);

    const [clientSecret, paymentIntentId] = await handleCreatePayment(payment.amount, payment.currency);

    if (clientSecret && paymentIntentId) {
      await handleConfirmPayment(clientSecret, paymentIntentId, payment.amount, payment.currency);
    } else {
      setDisableStopButton(false);
    }
  };

  const handleCreatePayment = async (amount: number, currency: string): Promise<[string, string] | [null, null]> => {
    try {
      if (!payment?.chargePointId || !payment.evseId) {
        handleAndMonitorError(`Can't create payment because either chargePointId or evseId is null!`);

        return [null, null];
      }

      const createPaymentIntentResponse = await createPayment(payment.chargePointId, payment.evseId, amount, currency);

      if (createPaymentIntentResponse.status !== 200) {
        handleError(createPaymentIntentResponse, t('app.payment_method.create.payment.intent.error'));
        return [null, null];
      }

      return [createPaymentIntentResponse.data.clientSecret, createPaymentIntentResponse.data.paymentIntentId];
    } catch (e) {
      handleAndMonitorError(
        `Error while trying to create payment intent from ${ENDPOINT_URL.stripePayments} with amount ${amount} and currency ${currency}: ${e}`
      );
      return [null, null];
    }
  };

  const handleConfirmPayment = async (
    clientSecret: string,
    paymentIntentId: string,
    amount: number,
    currency: string
  ) => {
    const confirmPaymentResponse = await stripe?.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    if (confirmPaymentResponse?.error) {
      handleError(confirmPaymentResponse.error, t('app.payment_method.confirm.payment.error'));
      if (payment) {
        await createStripeFailedTransaction(amount, currency, paymentIntentId, confirmPaymentResponse?.error.message);
      }

      setDisableStopButton(false);

      return;
    }

    if (confirmPaymentResponse?.paymentIntent.status === 'requires_capture') {
      if (payment) {
        const { data } = await getPayment(paymentIntentId);

        const updatedPayment = {
          ...payment,
          paymentIntentId: paymentIntentId,
          stopSessionMode: data?.payment?.stopSessionMode,
        };

        LocalStorage.setItem(PAYMENT_KEY, updatedPayment);

        if (data.payment.stopSessionMode === StopSessionMode.PIN_CODE) {
          navigate(
            `../${ROUTE_NAMES.choosePaymentMethod}/${confirmPaymentResponse.paymentIntent.id}/${ROUTE_NAMES.howToSetPinCode}`,
            {
              state: {
                paymentIntentId: confirmPaymentResponse.paymentIntent.id,
              },
            }
          );
        } else {
          try {
            const response = await startSession({
              chargePointId: updatedPayment.chargePointId,
              evseId: updatedPayment.evseId,
              pin: '', // It'll be set from the backend
              paymentIntentId: updatedPayment.paymentIntentId,
              depositAmount: updatedPayment.amount,
              currency: updatedPayment.currency,
              connectorId: updatedPayment.connectorId,
            });

            const sessionId = response?.data?.sessionId;
            const pin = response?.data?.pin;

            if (sessionId) {
              navigate(`../${ROUTE_NAMES.chargingSession}/${sessionId}`, {
                state: {
                  pin: pin,
                  enterPin: true,
                  sessionId,
                },
                replace: true,
              });
            }
          } catch (e) {
            handleAndMonitorError(`Error while trying to validate last4: ${e}`);
            navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
              state: {
                status: SessionStatus.failed,
              },
            });
          }
        }
        return;
      }
    }

    setDisableStopButton(false);

    handleError(confirmPaymentResponse, t('app.payment_method.confirm.payment.error'));
  };

  return (
    <section>
      <section>
        <section id="payment-element"></section>
        <section className={styles.buttonContainer}>
          <Button
            label={processingPayment ? t('app.payment_method.button.loading') : t('app.payment_method.button.pay.now')}
            type={ButtonType.primary}
            onClick={handleSubmit}
            icon={processingPayment ? GIF.waveLoaderWhite : FA_ICONS.faAngleRight}
            classNameIcon={processingPayment ? styles.icon : ''}
            disabled={disableStopButton}
          />
        </section>
      </section>

      <section className="general-error">{errorMessage && <div>{errorMessage}</div>}</section>
    </section>
  );
};

export default Stripe;
