import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import styles from './styles.module.css';
import Button, { ButtonType } from '../../components/Button';
import Header from '../../components/Header';
import Image from '../../components/Image';
import Loader from '../../components/Loader';
import { SESSION_STATUS_ICONS } from '../../constants';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useConfig from '../../hooks/useConfig';
import { SessionBillingStatus, SessionStatus } from '../../models/session';
import { ROUTE_NAMES } from '../../router/route-names';

interface ScreenData {
  header?: string;
  icon: string;
  code: string;
  description: React.ReactNode;
}

const ChargingSessionFailed = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { receiptsUrl, displayName } = useConfig();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ScreenData>({
    icon: SESSION_STATUS_ICONS.unknown,
    code: t('app.charging_session.unknown.error.code'),
    description: t('app.charging_session.unknown.error.description'),
  });

  useEffect(() => {
    if (
      location?.state?.status &&
      [SessionStatus.failed, SessionStatus.unknown, SessionStatus.expired].includes(location.state.status)
    ) {
      setData({
        icon: SESSION_STATUS_ICONS[
          location.state.status as Exclude<
            SessionStatus,
            SessionStatus.finished | SessionStatus.active | SessionStatus.pending
          >
        ],
        code: t(location.state.title ?? `app.charging_session.${location.state.status}.error.code`),
        description: t(location.state.description ?? `app.charging_session.${location.state.status}.error.description`),
      });
    } else if (
      location?.state?.billingStatus &&
      [SessionBillingStatus.suspended].includes(location.state.billingStatus)
    ) {
      setData({
        icon: SESSION_STATUS_ICONS.expired,
        code: t(location.state.title ?? ``),
        description: (
          <span
            dangerouslySetInnerHTML={{
              __html: t(location.state.description ?? ``)
                .replace('%s1', `<a href="${receiptsUrl}" target="_blank" rel="noopener noreferrer">receipt portal</a>`)
                .replace('%s2', displayName),
            }}
          />
        ),
      });
    }

    setLoading(false);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handleGoBack = () => {
    navigate(`../${ROUTE_NAMES.selectEvse}`);
  };

  return (
    <section className="page-container">
      <Header showBackButton onClick={handleGoBack} />
      <section className={styles.container}>
        <section className={styles.icon}>
          <Image src={data.icon} />
        </section>
        <section className={`${styles.code} bold`}>{data.code}</section>
        <section className={styles.description}>{data.description}</section>
        <Button label={t('app.charging_session.failed.button')} type={ButtonType.primary} onClick={handleGoBack} />
      </section>
    </section>
  );
};

export default ChargingSessionFailed;
