/**
 * turns tenant language (en_gb-x-tenantName) to regular language code (en_GB)
 * @param lng
 * @returns
 */
export const formatPaymentLangCode = (lng?: string) => {
  if (!lng) return;

  const strippedTenant = lng.split('-')[0];
  const codeParts = strippedTenant.split('_');

  const countryUpper = codeParts[1].toLocaleUpperCase();

  return [codeParts[0], countryUpper].join('_');
};
