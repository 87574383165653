import type { GenericAbortSignal } from 'axios';

import { ENDPOINT_URL } from '../../constants/endpoints';
import { Http } from '../../http';
import type { Payment } from '../../models/payment';
import type { SessionSummary } from '../../models/session';
import useConfig from '../useConfig';

export default function useSession() {
  const { name } = useConfig();

  const getSession = async (
    chargePointId: number,
    evseId: number,
    sessionId: number,
    pin: string,
    signal?: GenericAbortSignal
  ) => await new Http().get(ENDPOINT_URL.session(chargePointId, evseId, sessionId, pin), name, signal);

  const getFinishedSession = async (payment: Payment, sessionId: string) =>
    await new Http().get(ENDPOINT_URL.sessionFinished(payment.chargePointId, payment.evseId, sessionId), name);

  const checkSessionAvailability = async (chargePointId: number, evseId: number) =>
    await new Http().get(ENDPOINT_URL.checkSessionAvailability(chargePointId, evseId), name);

  const startSession = async ({
    chargePointId,
    evseId,
    pin,
    paymentIntentId,
    currency,
    connectorId,
    depositAmount,
  }: {
    chargePointId: number;
    evseId: number;
    pin: string;
    paymentIntentId: Payment['paymentIntentId'];
    currency: string;
    connectorId: number;
    depositAmount: number;
  }) =>
    await new Http().post(
      ENDPOINT_URL.startSession(chargePointId, evseId),
      {
        pin,
        paymentIntentId,
        depositAmount,
        currency,
        connectorId,
      },
      name
    );

  const stopSession = async (chargePointId: number, evseId: number, pin: string) =>
    await new Http().post(
      ENDPOINT_URL.stopSession(chargePointId, evseId),
      {
        pin,
      },
      name
    );

  const getReceipt = async (session: SessionSummary) => {
    if (!session.receiptId) return;

    const receiptResponse = await new Http().get(ENDPOINT_URL.receipt(session.receiptId), name);

    return receiptResponse;
  };

  return {
    startSession,
    stopSession,
    getSession,
    getFinishedSession,
    getReceipt,
    checkSessionAvailability,
  };
}
