import React from 'react';

// Components

import styles from './styles.module.css';
import { FA_ICONS, GIF } from '../../constants';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import Image from '../Image';
import Modal from '../Modal';

const PreparingModal = () => {
  const { t } = useAppTranslation();

  return (
    <Modal>
      <section className={styles.container}>
        <header className={styles.header}>
          <Image src={FA_ICONS.faChargePreparing} />
          <p className="bold">{t('app.charging_session.modal.header')}</p>
        </header>
        <main>
          <Image src={GIF.waveLoader} />
        </main>
      </section>
    </Modal>
  );
};

export default PreparingModal;
