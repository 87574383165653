import type { GenericAbortSignal } from 'axios';
import axios from 'axios';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import styles from './styles.module.css';
import Button, { ButtonType } from '../../components/Button';
import ChargePointCard from '../../components/ChargePointContainer/ChargePointCard';
import Header from '../../components/Header';
import Image from '../../components/Image';
import Loader from '../../components/Loader';
import PreparingModal from '../../components/PreparingModal';
import { FA_ICONS, GIF } from '../../constants';
import { CURRENCY_SYMBOL } from '../../constants/currencySymbol';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { convertWattToKilowatt, handleAndMonitorError } from '../../helpers';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useNavigationHandler from '../../hooks/useNavigationHandler';
import useSession from '../../hooks/useSession';
import type { ChargePoint } from '../../models/chargePoint';
import type { Payment } from '../../models/payment';
import { SessionStatus } from '../../models/session';
import type { Session } from '../../models/session';
import { ROUTE_NAMES } from '../../router/route-names';
import { CHARGE_POINT_KEY, LocalStorage, PAYMENT_KEY } from '../../storage';
import { REFRESH_INTERVAL } from '../../constants/intervals';

// constant for getting first time session data after 5 seconds (requirement)
const TIMEOUT_PERIOD = 5000;

const POWER_ALLOWED_DECIMALS = 2;

const ChargingSession = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string | undefined>();
  const [preparing, setPreparing] = useState(false);
  const [session, setSession] = useState<Session | undefined>();
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment;
  const chargePoint = LocalStorage.getItem(CHARGE_POINT_KEY) as ChargePoint | undefined;
  const { stopSession, getSession } = useSession();
  const { handleGoHomeButton } = useNavigationHandler();
  const [disableStopButton, setDisableStopButton] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();
  const interval = useRef<NodeJS.Timeout>();
  const retrieveSessionAbortController = useRef<AbortController | null>(null);

  const getSessionData = useCallback(
    async (signal?: GenericAbortSignal): Promise<Session | undefined> => {
      const sessionResponse = await getSession(
        payment.chargePointId,
        payment.evseId,
        location?.state?.sessionId,
        location?.state?.pin,
        signal
      );

      return sessionResponse?.data?.data?.session;
    },
    [getSession, location?.state?.pin, location?.state?.sessionId, payment.chargePointId, payment.evseId]
  );

  const handleSessionData = useCallback(
    (sessionData: Session) => {
      switch (sessionData.status) {
        case SessionStatus.pending:
          setPreparing(true);
          break;
        case SessionStatus.active:
          // Avoid unnecessary rerenders in case new data is same as the previous one:
          if (!session || !_.isEqual(sessionData, session)) {
            setSession(sessionData);
          }
          setPreparing(false);
          break;
        case SessionStatus.expired:
          navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
            state: {
              status: SessionStatus.failed,
              title: 'app.errors.title.warning',
              description: 'app.errors.descr.expired_session',
            },
            replace: true,
          });
          break;
        case SessionStatus.finished:
          if (disableStopButton === true) {
            break; // The button navigation is doing the job. There is a rare case where both navigation are triggered which produces 404 error(i.e. "../summary/summary" page which doesn't exist).
          }

          navigate(ROUTE_NAMES.sessionSummary, {
            state: {
              sessionId: sessionData.id,
              pin: location.state.pin,
            },
            replace: true,
          });
          break;
        default:
          navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
            state: {
              status: sessionData.status,
            },
            replace: true,
          });
          break;
      }
    },
    [session, navigate, disableStopButton, setPreparing, setSession, location]
  );

  const retrieveSession = useCallback(
    async (abortSignal: GenericAbortSignal) => {
      if (!location.state.sessionId) return;

      try {
        const sessionData = await getSessionData(abortSignal);

        if (!sessionData) {
          throw new Error();
        }

        handleSessionData(sessionData);
      } catch (e) {
        //Handle the case where the request is aborted
        if (axios.isCancel(e)) {
          return;
        }

        if (e?.response?.data?.error === 'app.enter_pin_code.incorrect.pin') {
          navigate(`../${ROUTE_NAMES.enterPinCode}`, {
            state: {
              error: e?.response?.data?.error,
            },
          });
        } else {
          let jsonError;

          try {
            jsonError = JSON.parse(e?.response.data.error);
          } catch (je) {}

          if (jsonError && jsonError.localeTitleKey && jsonError.localeDescrKey) {
            navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
              state: {
                status: SessionStatus.failed,
                title: jsonError.localeTitleKey,
                description: jsonError.localeDescrKey,
              },
              replace: true,
            });
          } else {
            handleGoHomeButton();
          }
        }

        handleAndMonitorError(
          `Error while trying to access Charging Session screen from ${ENDPOINT_URL.session(
            payment.chargePointId,
            payment.evseId,
            payment?.sessionId ?? -1,
            location.state.pin
          )}: ${e?.response?.data?.error ?? e}`
        );
      }
    },
    [location, navigate, handleGoHomeButton, handleSessionData]
  );

  useEffect(() => {
    if (!location?.state?.pin) {
      navigate(`../${ROUTE_NAMES.enterPinCode}`);
      return;
    }

    if (!retrieveSessionAbortController.current) {
      retrieveSessionAbortController.current = new AbortController();
    }

    if (retrieveSessionAbortController.current !== null) {
      if (!timeout.current) {
        timeout.current = setTimeout(
          () => {
            retrieveSession(retrieveSessionAbortController.current!.signal);
          },
          location?.state?.enterPin ? 0 : TIMEOUT_PERIOD
        );
      }
      if (!interval.current) {
        interval.current = setInterval(() => {
          retrieveSession(retrieveSessionAbortController.current!.signal);
        }, REFRESH_INTERVAL);
      }
    }
  }, [location, retrieveSession]);

  useEffect(() => {
    // Cleanup the timeout and abort in a useEffect with no deps so it acts as unmount, otherwise the cleanup happens every time deps change
    return () => {
      if (retrieveSessionAbortController.current) {
        retrieveSessionAbortController.current.abort();
      }
      clearTimeout(timeout.current);
      clearInterval(interval.current);
    };
  }, []);

  if (!chargePoint && !preparing) {
    return <Loader />;
  }

  if (!payment) {
    throw 'Missing payment details';
  }

  const handleStopSession = async () => {
    if (!location?.state?.pin) {
      throw 'Missing pin code';
    }

    try {
      setDisableStopButton(true);

      const stopSessionResponse = await stopSession(payment.chargePointId, payment.evseId, location?.state?.pin);

      if (stopSessionResponse.status !== 200) {
        setDisableStopButton(false);

        console.log('Error while trying to stop a session', stopSessionResponse);

        setError(t('app.charging_session.end.session.error'));

        return;
      }

      navigate(ROUTE_NAMES.sessionSummary, {
        state: {
          sessionId: stopSessionResponse?.data?.sessionId,
          pin: location?.state?.pin,
        },
        replace: true,
      });
    } catch (e) {
      handleAndMonitorError(
        `Error while trying to stop a session from ${ENDPOINT_URL.stopSession(
          payment.chargePointId,
          payment.evseId
        )}: ${e}`
      );
      setError(t('app.charging_session.end.session.error'));
    } finally {
      setDisableStopButton(false);
    }
  };

  const amountData = (currency: string, price: number): string => {
    return `${CURRENCY_SYMBOL[currency]} ${price.toFixed(POWER_ALLOWED_DECIMALS)}`;
  };

  const energyData = (energy: number): string => {
    return `${convertWattToKilowatt(energy).toFixed(POWER_ALLOWED_DECIMALS)} kWh`;
  };

  return (
    <section className="page-container">
      <Header showBackButton label={t('app.charging_session.header')} onClick={handleGoHomeButton} />
      <ChargePointCard
        locationName={chargePoint?.locationName}
        address={chargePoint?.address}
        className={styles.chargingPoint}
      />
      {session && (
        <>
          <section className={styles.mainContent}>
            <section className={`blue-card ${styles.charging}`}>
              <Image src={FA_ICONS.faCharge} />
              <span>{t('app.charging_session.status.label')}</span>
            </section>
            <hr className="separator" />
            {!preparing && (
              <section className={`blue-card ${styles.battery}`}>
                <Image src={GIF.battery} />
              </section>
            )}
            <hr className="separator" />
            <section className={`blue-card ${styles.details}`}>
              <section className={styles.detailsRow}>
                <span>{t('app.charging_session.details.power.label')}</span>
                <span className="bold">{`${session.powerKw.toFixed(POWER_ALLOWED_DECIMALS)} kW`}</span>
              </section>
              <section className={styles.detailsRow}>
                <span>{t('app.charging_session.details.deposit.left.label')}</span>
                <span className="bold">{amountData(session.currency, payment.amount - session.amount)}</span>
              </section>
              <section className={styles.detailsRow}>
                <span>{t('app.charging_session.details.energy.label')}</span>
                <span className="bold">{energyData(session.energy)}</span>
              </section>
              <section className={styles.detailsRow}>
                <span>{t('app.charging_session.details.amount.label')}</span>
                <span className="bold">{amountData(session.currency, session.amount)}</span>
              </section>
            </section>
          </section>
          <Button
            label={t('app.charging_session.end.session.button')}
            type={ButtonType.primary}
            onClick={handleStopSession}
            disabled={disableStopButton}
          />
        </>
      )}
      {error && <span className={styles.error}>{error}</span>}
      {preparing && <PreparingModal />}
    </section>
  );
};

export default ChargingSession;
