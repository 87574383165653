import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';
import Button, { ButtonType } from '../../components/Button';
import ChargePointContainer from '../../components/ChargePointContainer';
import DownloadApps from '../../components/DownloadApps';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import ReceiptsUrl from '../../components/ReceiptsUrl/ReceiptsUrl';
import { FA_ICONS } from '../../constants';
import { DisplayMode } from '../../constants/display-mode';
import { buildTenantUrl } from '../../helpers/routing';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useChargePointData from '../../hooks/useChargePointData';
import useConfig from '../../hooks/useConfig';
import { EvseStatus, HardwareStatus } from '../../models/evse';
import { ROUTE_NAMES } from '../../router/route-names';

const ChargePoint = () => {
  const { t } = useAppTranslation();
  const { chargePoint, availableCount, evses, tenant, error } = useChargePointData();
  const { languages, appStoreUrl, googleStoreUrl, receiptsUrl, displayMode } = useConfig();
  const navigate = useNavigate();

  if (!chargePoint) {
    if (!error) {
      return <Loader />;
    }

    return (
      <section className="page-container">
        <section className="general-error">{t('app.charge_point.missing.details.error')}</section>
      </section>
    );
  }

  if (evses.length === 1) {
    navigate(
      buildTenantUrl({
        pathPrefix: '..',
        tenant: tenant,
        chargePointId: chargePoint.id,
        routeName: ROUTE_NAMES.selectEvse,
      })
    );
    return;
  }

  return (
    <section className="page-container">
      <Header
        showBackButton={languages && languages?.length ? true : false}
        label={t('app.charge_point.header')}
        onClick={() =>
          navigate(
            buildTenantUrl({
              pathPrefix: '..',
              tenant: tenant,
              routeName: ROUTE_NAMES.chooseLanguage,
            })
          )
        }
      />
      <main>
        <ChargePointContainer
          locationName={chargePoint.locationName}
          address={chargePoint.address}
          numberOfEvses={availableCount}
          hardwareStatus={HardwareStatus.available}
          status={EvseStatus.enabled}
        />
        <hr className={styles.line} />
        <Button
          label={t('app.charge_point.continue.button')}
          type={ButtonType.primary}
          icon={FA_ICONS.faAngleRight}
          onClick={() =>
            navigate(
              buildTenantUrl({
                pathPrefix: '..',
                tenant: tenant,
                chargePointId: chargePoint.id,
                routeName: ROUTE_NAMES.selectEvse,
              })
            )
          }
        />
        {(!!appStoreUrl || !!googleStoreUrl) && (
          <>
            <hr className={styles.line} />
            <section className={styles.bold}>{t('app.charge_point.or.separator.label')}</section>
            <hr className={styles.line} />
            <DownloadApps appStoreUrl={appStoreUrl} googleStoreUrl={googleStoreUrl} />
          </>
        )}
        {receiptsUrl && displayMode === DisplayMode.ChargePoint && <ReceiptsUrl />}
      </main>
    </section>
  );
};

export default ChargePoint;
