import { Appearance, StripePaymentElementOptions, Stripe, StripeElements } from '@stripe/stripe-js';

import { Payment } from 'src/models/payment';

// Customize the look and feel of Stripe Elements
// documentation => https://stripe.com/docs/elements/appearance-api
export const appearance: Appearance = {
  theme: 'stripe',
  variables: {
    fontFamily: ' "Inter", sans-serif',
    fontSizeBase: '16px',
    borderRadius: '8px',
    colorBackground: '#eff5fc',
    colorPrimary: '#000000',
    colorDanger: '#ff6868',
  },
  rules: {
    '.AccordionItem': {
      border: 'none',
      fontSize: '22px',
      fontWeight: '600',
    },
    '.AccordionItem--selected': {
      border: '1px solid #7DC35F',
    },
    '.Label': {
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '15.73px',
      marginLeft: '8px',
    },
  },
};

export enum OptionsMode {
  payment = 'payment',
  setup = 'setup',
  subscription = 'subscription',
}

export const paymentOptions: StripePaymentElementOptions = {
  layout: {
    type: 'accordion',
    defaultCollapsed: false,
    radios: true,
    spacedAccordionItems: true,
  },
};

export const initElementOptions = (
  mode: OptionsMode,
  amount: number,
  currency: string,
  appearanceElement: Appearance
) => {
  return {
    mode,
    amount,
    currency,
    appearance: appearanceElement,
  };
};

// export const amountToBeCollected = (amount: number): number => {
//   /*
//     https://stripe.com/docs/api/payment_intents/create#create_payment_intent-amount
//     Amount intended to be collected by this PaymentIntent.
//     A positive integer representing how much to charge in the smallest currency unit (e.g., 100 cents to charge $1.00)
//   */
//   return amount * 100;
// };

export const initPaymentElement = (
  stripe: Stripe,
  payment: Payment,
  setElements: React.Dispatch<React.SetStateAction<StripeElements | undefined>>
) => {
  const stripeElements = stripe.elements(
    initElementOptions(OptionsMode.payment, payment.amount * 100, payment.currency.toLowerCase(), appearance)
  );
  setElements(stripeElements);
  const paymentElement = stripeElements.create(OptionsMode.payment, paymentOptions);
  paymentElement.mount('#payment-element');
};
