import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import PinCode from '../../components/PinCode';
import { DisplayMode } from '../../constants/display-mode';
import { checkPinCode } from '../../helpers';
import { buildTenantUrl, checkPathnameIncludes } from '../../helpers/routing';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useConfig from '../../hooks/useConfig';
import { StopSessionMode } from '../../models/config';
import type { Payment } from '../../models/payment';
import { ROUTE_NAMES } from '../../router/route-names';
import { LocalStorage, PAYMENT_KEY } from '../../storage';
import EnterLast4 from '../EnterLast4';

const EnterPinCode = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { name, displayMode } = useConfig();

  const [error, setError] = useState<string | null>(location?.state?.error);

  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;

  const isPinMode = payment?.stopSessionMode === StopSessionMode.PIN_CODE;

  const handleEnterPinCode = (pinCode: string) => {
    const validationResult = checkPinCode(pinCode, t);

    if (validationResult.isInvalid) {
      setError(validationResult.errorMessage);
      return;
    }

    setError(null);

    if (location?.state?.summary) {
      navigate(`../${ROUTE_NAMES.chargingSession}/${payment?.sessionId}/${ROUTE_NAMES.sessionSummary}`, {
        state: {
          pin: pinCode,
        },
        replace: true,
      });
      return;
    }

    navigate(`../${ROUTE_NAMES.chargingSession}/${payment?.sessionId}`, {
      state: {
        pin: pinCode,
        enterPin: true,
        sessionId: payment?.sessionId,
      },
      replace: true,
    });
  };

  const handleBackButton = () => {
    if (displayMode === DisplayMode.Search) {
      navigate(`../${ROUTE_NAMES.selectEvse}`);
    } else {
      if (checkPathnameIncludes('tenant')) {
        navigate(`../${ROUTE_NAMES.selectEvse}`);
      } else {
        navigate(
          buildTenantUrl({
            pathPrefix: '..',
            tenant: name,
            routeName: ROUTE_NAMES.selectEvse,
          })
        );
      }
    }
  };

  return isPinMode ? (
    <section className="page-container">
      <PinCode
        showBackButton
        headerLabel={t('app.enter_pin_code.header')}
        buttonLabel={t('app.enter_pin_code.proceed.button')}
        onBackClick={handleBackButton}
        onForwardClick={handleEnterPinCode}
        error={error}
      />
      {/* NOTE: This button is not used with the Pin Code flow, since mobile payment users do not know the card details required */}
      {/* <section className={styles.button}>
        <TextButton
          label={t('app.enter_pin_code.forgot.button')}
          onClick={() => navigate(`../${ROUTE_NAMES.reEnterCardDetails}`)}
        />
      </section> */}
    </section>
  ) : (
    <EnterLast4 />
  );
};

export default EnterPinCode;
