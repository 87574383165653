import React from 'react';

import styles from './styles.module.css';
import { formatPaymentLangCode } from '../../helpers/language';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useConfig from '../../hooks/useConfig';

const ReceiptsUrl = () => {
  const { t, i18n } = useAppTranslation();
  const curLang = i18n.resolvedLanguage;
  const { receiptsUrl } = useConfig();

  const receiptsUrlWithLang = `${receiptsUrl}?lang=${formatPaymentLangCode(curLang)}`;

  return (
    <section>
      <hr className={styles.line} />
      <section className={styles.receipt}>
        <span>{t('app.charge_point.receipt.label')}</span>
        <span>
          {t('app.charge_point.receipt.visit.label')}&nbsp;
          <a className={styles.link} href={receiptsUrlWithLang} target="_blank" rel="noreferrer">
            {t('app.charge_point.receipt.visit.url')}
          </a>
        </span>
      </section>
    </section>
  );
};

export default ReceiptsUrl;
