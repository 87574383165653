// Extracted Types
export interface Connector {
  id: number;
  type: ConnectorType | string;
  format: string;
}

export interface Pricing {
  timePeriods: TimePeriod[];
  pricePerKwh: number;
  connectionFee: number;
  minPrice: number;
  idleFeePerMinute: number;
  durationFeeGracePeriod: number;
  connectionFeeMinimumSessionDuration: number;
  connectionFeeMinimumSessionEnergy: number;
  dayPricePerKwh?: number;
  dayIdleFeePerMinute?: number;
  dayPricePerPeriod?: number;
  nightPricePerKwh?: number;
  nightIdleFeePerMinute?: number;
  nightPricePerPeriod?: number;
  pricePerPeriod?: number;
  pricePeriodInMinutes?: number;
  idleFeeGracePeriodMinutes?: number;
  idlePricingPeriodInMinutes?: number;
}

export interface Restrictions {
  applyToUsersOfChargePointOwner?: boolean;
  applyToUsersOfAllRoamingEmsps?: boolean;
  applyToAdHocUsers?: boolean;
  adHocPreAuthorizeAmount: number;
  applyToUsersOfPartners?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  applyToUsersWithGroups?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  applyToUserGroupIds?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  applyToUsersWithSubscriptions?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface Tariff {
  id: number;
  name: string;
  type: TariffType;
  currency: string;
  description: Record<string, string | null>;
  additionalInformation: {
    en: null;
  };
  learnMoreUrl: {
    en: null;
  };
  dayTariffStart: string;
  nightTariffStart: string;
  pricing: Pricing;
  restrictions: Restrictions;
  stopSession: {
    timeLimitMinutes: null;
    stopWhenEnergyExceedsKwh: null;
  };
  display?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface EvseModel {
  id: number;
  physicalReference: string;
  currentType: CurrentType;
  networkId: string;
  status: EvseStatus;
  hardwareStatus: HardwareStatus;
  powerOptions: {
    maxPower: number;
    maxVoltage: string;
    maxAmperage: null;
    phases: string;
    phaseRotation: string;
  };
  format: string;
  connectors: Connector[];
  tariff: Tariff;
  label?: string;
}

export type DetailsSectionProps = {
  id?: number;
  physicalReference?: string;
  status?: EvseStatus;
  hardwareStatus?: HardwareStatus;
  connectors?: Connector[];
  tariff: Tariff;
  tenant?: string;
  chargePointId?: number;
  socPercent?: number;
  activeSession?: boolean;
  sessionId?: number;
};

export enum HardwareStatus {
  available = 'available',
  preparing = 'preparing',
  charging = 'charging',
  suspendedEVSE = 'suspendedEVSE',
  suspendedEV = 'suspendedEV',
  finishing = 'finishing',
  reserved = 'reserved',
  unavailable = 'unavailable',
  faulted = 'faulted',
  occupied = 'occupied',
  null = 'null',
}

export enum EvseStatus {
  enabled = 'enabled',
  disabled = 'disabled',
  outOfOrder = 'out of order',
}

export enum CurrentType {
  ac = 'ac',
  dc = 'dc',
}

export enum ConnectorType {
  type1 = 'type1',
  type2 = 'type2',
  type3 = 'type3',
  chademo = 'chademo',
  ccs1 = 'ccs1',
  ccs2 = 'ccs2',
  j1772 = 'j1772',
  'gb-t-ac' = 'gb-t-ac',
  'gb-t-dc' = 'gb-t-dc',
  catarc = 'catarc',
  schuko = 'schuko',
  cee16 = 'cee16',
  cee32 = 'cee32',
  inductive = 'inductive',
  'nema-5-20' = 'nema-5-20',
  'type-e-french' = 'type-e-french',
  'type-g-british' = 'type-g-british',
  'type-j-swiss' = 'type-j-swiss',
  avcon = 'avcon',
}

export enum TariffType {
  DURATION_ENERGY = 'duration+energy',
  DURATION_ENERGY_TOD = 'duration+energy time of day',
  STANDARD_TOD = 'standard_tod',
  ENERGY_TOU = 'energy tou',
  STANDARD = 'standard',
}


export interface TariffData {
  hour: string;
  hourEnd: string;
  durationFee?: number;
  energyFee?: number;
  connectionFee?: number;
  idleFee?: number;
  price?: number;
}

// Define interfaces for the pricing data structure
export interface TimePeriod {
  startTime: string;
  endTime: string;
}

export interface FeePeriod {
  fee: number[];
}

export interface PricePeriods {
  connectionFeePeriods?: FeePeriod;
  energyFeePeriods?: FeePeriod;
  durationFeePeriods?: FeePeriod;
  idleFeePeriods?: FeePeriod;
  [key: string]: unknown;
}

export interface FlexibleMarkUp {
  intervalPricing: {
    startsAt: string;
    endsAt: string;
    price: number;
    [key: string]: unknown;
  }[];
  [key: string]: unknown;
}

export interface PricingData {
  timePeriods?: TimePeriod[];
  pricePeriods?: PricePeriods;
  daysWhenApplied?: SpecialDay[];
  flexibleMarkUpAsFixedPerKwh?: FlexibleMarkUp;
  [key: string]: unknown;
}

/**
 * Represents a special day tariff structure
 */
export interface SpecialDay {
  name: string;
  specialDates: string[];
  connectionFeeMinimumSessionDuration: number | null;
  connectionFeeMinimumSessionEnergy: number | null;
  pricePeriodInMinutes: number;
  durationFeeGracePeriod: number | null;
  idlePricingPeriodInMinutes: number;
  idleFeeGracePeriodMinutes: number | null;
  pricePeriods: PricePeriods;
}