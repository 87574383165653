import type { AxiosResponse } from 'axios';
import { useCallback, useLayoutEffect, useState } from 'react';

import { ENDPOINT_URL } from '../../constants/endpoints';
import { handleAndMonitorError } from '../../helpers';
import { formatEvsePhysicalReference } from '../../helpers/charge-point-validate';
import { Http } from '../../http';
import type { ChargePoint } from '../../models/chargePoint';
import { EvseStatus, HardwareStatus } from '../../models/evse';
import type { EvseModel } from '../../models/evse';
import { ROUTE_NAMES } from '../../router/route-names';
import { CHARGE_POINT_KEY, LocalStorage } from '../../storage';
import useConfig from '../useConfig';

const useChargePointData = () => {
  const [tenant, setTenant] = useState<string>('');
  const [chargePoint, setChargePoint] = useState<ChargePoint>();
  const [evses, setEvses] = useState<EvseModel[]>([]);
  const [availableCount, setAvailableCount] = useState<number>(0);
  const [error, setError] = useState(false);
  const { name } = useConfig();

  const setEvseData = useCallback(
    async ({ shouldTryeMI3, evseId, tenantName }: { shouldTryeMI3: boolean; evseId: string; tenantName: string }) => {
      const response = await new Http().get<AxiosResponse<{ chargePoint: ChargePoint; evses: EvseModel[] }>>(
        ENDPOINT_URL.chargePoint(shouldTryeMI3 ? formatEvsePhysicalReference(evseId) : evseId),
        name
      );
      const data = response.data.data;

      if (!data) return;

      setChargePoint(data.chargePoint);
      setTenant(tenantName ?? response.headers['tenant']);
      LocalStorage.setItem(CHARGE_POINT_KEY, data.chargePoint);

      let count = 0;
      const evsesToDisplay: EvseModel[] = data.evses.filter(evse => {
        if (evse.hardwareStatus) {
          if (evse.hardwareStatus === HardwareStatus.available && evse.status === EvseStatus.enabled) {
            count++;
          }
          return evse;
        }
      });

      setEvses(evsesToDisplay);
      setAvailableCount(count);
    },
    [name]
  );

  const dataFetch = useCallback(async () => {
    const path = window.location.pathname.split('/');
    const evseIndex = path.findIndex(element => element === 'evse');
    const startSessionIndex = path.findIndex(element => element === ROUTE_NAMES.sessionStart);
    const evsePhysicalReference = evseIndex < 0 ? path[startSessionIndex + 1] : path[evseIndex + 1];
    const tenantIndex = path.findIndex(element => element === 'tenant');
    const tenantName = path[tenantIndex + 1];

    try {
      if (!evsePhysicalReference || evsePhysicalReference === 'undefined') {
        throw new Error('missing evsePhysicalReference details');
      }
      await setEvseData({ shouldTryeMI3: false, evseId: evsePhysicalReference, tenantName });
    } catch (e) {
      try {
        await setEvseData({ shouldTryeMI3: true, evseId: evsePhysicalReference, tenantName });
      } catch (nextError) {
        setError(true);
        handleAndMonitorError(
          `Error while trying to retrieve charge point details from ${ENDPOINT_URL.chargePoint(evsePhysicalReference ?? '')}: ${nextError}`
        );
      }
    }
  }, [setEvseData]);

  useLayoutEffect(() => {
    dataFetch();
  }, [dataFetch]);

  return { tenant, chargePoint, evses, availableCount, error, dataFetch };
};

export default useChargePointData;
