import React from 'react';

import StatusIndicator from '../StatusIndicator';
import ChargePointCard from './ChargePointCard';
import type { EvseModel } from '../../models/evse';

interface ChargePointCointainerProps {
  locationName: string | undefined;
  address: string | undefined;
  numberOfEvses: number;
  hardwareStatus: EvseModel['hardwareStatus'];
  status: EvseModel['status'];
}

const ChargePointContainer = ({
  locationName,
  address,
  numberOfEvses,
  status,
  hardwareStatus,
}: ChargePointCointainerProps) => {
  return (
    <>
      <ChargePointCard locationName={locationName} address={address} />
      <hr className="separator" />
      <StatusIndicator hardwareStatus={hardwareStatus} status={status} numberOfEvses={numberOfEvses} />
    </>
  );
};

export default ChargePointContainer;
