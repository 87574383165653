type TenantUrlOptions = {
  tenant: string;
  routeName?: string;
  chargePointId?: string | number;
  evseId?: string;
  extraPath?: string;
  pathPrefix?: string;
};

export function buildTenantUrl({
  pathPrefix = '',
  tenant,
  routeName,
  chargePointId,
  evseId,
  extraPath = '',
}: TenantUrlOptions): string {
  let url = `/tenant/${tenant}`;
  const prefix = `${pathPrefix}`;
  if (pathPrefix) url = prefix + url;
  if (chargePointId) url += `/cp/${chargePointId}`;
  if (evseId) url += `/evse/${evseId}`;

  if (routeName) url += `/${routeName}`;
  if (extraPath) url += `/${extraPath}`;

  return url;
}

export function checkPathnameIncludes(segment: string): boolean {
  return window.location.pathname.includes(segment);
}
