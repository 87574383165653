import axios, { GenericAbortSignal } from 'axios';
import type { AxiosResponse } from 'axios';

export enum HTTPMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}

const BASE_URL = window.location.origin;

export class Http {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get = async <T = any,>(url: string, tenant?: string, signal?: GenericAbortSignal): Promise<AxiosResponse<T>> => {
    const response = await axios.get<T>(BASE_URL + url, {
      headers: { Tenant: tenant, 'tenant-path': window.location.href },
      signal
    });
    return response;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public post = async <T = any, B = any>(url: string, body: B, tenant?: string, signal?: GenericAbortSignal): Promise<AxiosResponse<T>> => {
    const response = await axios.post<T>(BASE_URL + url, body, {
      headers: { Tenant: tenant, 'tenant-path': window.location.href },
      signal
    });
    return response;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public patch = async <T = any, B = any>(url: string, body: B, tenant?: string, signal?: GenericAbortSignal): Promise<AxiosResponse<T>> => {
    const response = await axios.patch<T>(BASE_URL + url, body, {
      headers: { Tenant: tenant, 'tenant-path': window.location.href },
      signal
    });
    return response;
  };
}
