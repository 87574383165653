import { t } from 'i18next';
import React, { useCallback, useState } from 'react';

import { FA_ICONS } from '../../constants';
import { joinClass } from '../../helpers/joinClass';
import Button, { ButtonType } from '../Button';
import Header from '../Header';
import Image from '../Image';
import Notification from '../Notification';
import styles from './styles.module.css';

const PIN_CODE_LENGTH = 4;
const DIGITS = 10;

interface PinCodeProps {
  showBackButton: boolean;
  headerLabel: string;
  buttonLabel: string;
  buttonIcon?: string;
  error?: string | null;
  onBackClick?: () => void;
  onForwardClick: (pinCode: string) => void;
}

const PinCode = ({
  showBackButton,
  headerLabel,
  buttonLabel,
  buttonIcon,
  error,
  onBackClick,
  onForwardClick,
}: PinCodeProps) => {
  const [pinCode, setPinCode] = useState('');

  const digitClick = useCallback(
    (code: number) => {
      if (pinCode.length === PIN_CODE_LENGTH) {
        return;
      }
      setPinCode(currentCode => (currentCode += code));
    },
    [pinCode.length]
  );

  const onBackspace = () => {
    setPinCode(prev => prev.slice(0, -1));
  };

  const renderIcons = () => {
    return [...Array(PIN_CODE_LENGTH)].map((_, index) => {
      if (index < pinCode.length) {
        return <Image key={index} src={FA_ICONS.faCircleEntered} />;
      }
      return <Image key={index} src={FA_ICONS.faCircle} />;
    });
  };

  const handleSetPin = () => {
    onForwardClick(pinCode);
    setPinCode('');
  };

  const renderKeyboard = useCallback(() => {
    // create digits components array:
    const digits = [...Array(DIGITS)].map((_, index) => (
      <span
        key={index}
        className={joinClass(styles.digit, pinCode.length === PIN_CODE_LENGTH ? styles.disabled : undefined)}
        onClick={() => digitClick((index + 1) % DIGITS)}
      >
        {(index + 1) % DIGITS}
      </span>
    ));
    // take out the zero
    const zero = digits.pop();

    const keyboard = [
      ...digits,
      // insert a placeholder to the left of the zero, to keep the buttons properly aligned:
      <span key="placeholder" className={joinClass(styles.digit, styles.placeholder)}></span>,
      zero,
    ];

    // add the backspace button to the right of the zero:
    keyboard.push(
      <span
        className={joinClass(styles.digit, pinCode.length === 0 ? styles.disabled : undefined)}
        onClick={onBackspace}
      >
        <Image src={FA_ICONS.faBackspace} className={styles.backspace} />
      </span>
    );

    return keyboard;
  }, [digitClick, pinCode.length]);

  return (
    <>
      <Header showBackButton={showBackButton} label={headerLabel} onClick={onBackClick ? onBackClick : undefined} />
      {error && <Notification type="error" show={!!error} header={t(error)} position="unset" showClose />}
      <section className={styles.icons}>{renderIcons()}</section>

      <section className={styles.keyboardContainer}>{renderKeyboard()}</section>

      <Button
        label={buttonLabel}
        type={ButtonType.primary}
        onClick={handleSetPin}
        icon={buttonIcon}
        disabled={pinCode.length !== PIN_CODE_LENGTH}
      />
    </>
  );
};

export default PinCode;
