import type { Callback } from 'i18next';
import { useCallback, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from 'react-i18next';
import { getTenantLanguage } from 'src/i18n';
import { LANGUAGE_KEY, LocalStorage } from 'src/storage';

/**
 * Use this hook instead of useTranslation, especially when you need to change language
 * it provides an enhanced i18n.changeLanguage which sets the language with the tenant-specific code
 * @returns an enhanced useTranslation response
 */
export const useAppTranslation = () => {
  const useTranslationDef = useTranslation();

  const appChangeLanguage = useCallback(
    (lng: string, tenantName: string, callback?: Callback) => {
      const tenantLang = getTenantLanguage(lng, tenantName).toLowerCase();
      LocalStorage.setItem(LANGUAGE_KEY, tenantLang);
      return useTranslationDef.i18n.changeLanguage(tenantLang, callback);
    },
    [useTranslationDef.i18n]
  );

  const appUseTranslation = useMemo(
    () => ({
      ...useTranslationDef,
      i18n: {
        ...useTranslationDef.i18n,
        changeLanguage: appChangeLanguage,
      },
    }),
    [appChangeLanguage, useTranslationDef]
  );

  return appUseTranslation;
};
