import Bugsnag from '@bugsnag/js';
import type { TFunction } from 'i18next';
import { CURRENCY_SYMBOL } from 'src/constants/currencySymbol';

import type { Config } from '../models/config';
import { CONFIG_KEY, LocalStorage } from '../storage';

export * from './date-time';

export const capitalizeFirstLetter = (value: string | undefined): string => {
  if (!value) {
    return '';
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const decapitalizeFirstLetter = (value: string | undefined): string => {
  if (!value) {
    return '';
  }

  return value.charAt(0).toLowerCase() + value.slice(1);
};

export const convertWattToKilowatt = (value: number): number => {
  return value / 1000;
};

const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
export const isValidEmail = (email: string) => {
  return EMAIL_REGEX.test(email);
};

export const checkPinCode = (
  pin: string,
  translate: TFunction<'translation', undefined>
): { isInvalid: boolean; errorMessage: string | null } => {
  // Check if the pin is a string of exactly 4 digits
  if (!/^\d{4}$/.test(pin)) {
    return { isInvalid: true, errorMessage: translate('app.create_pin_code.error.message.four.digit.requirement') };
  }

  // Check for 4 consecutive incremental digits
  if (/0123|1234|2345|3456|4567|5678|6789/.test(pin)) {
    return {
      isInvalid: true,
      errorMessage: translate('app.create_pin_code.error.message.incremental.digits.requirement'),
    };
  }

  // Check for the same 4 digits
  if (/^(\d)\1{3}$/.test(pin)) {
    return { isInvalid: true, errorMessage: translate('app.create_pin_code.error.message.same.digits.requirement') };
  }

  // If none of the above conditions are met, the pin is valid
  return { isInvalid: false, errorMessage: null };
};

// debit/credit card expiration date => month validation
export const isValidMonth = (month: string | undefined): boolean => {
  if (!month) {
    return false;
  }

  const validMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  return validMonths.includes(month);
};

export const checkLast4 = (pin: string, translate: TFunction<'translation', undefined>) => {
  if (/^(\d)\1{3}$/.test(pin)) {
    return { isInvalid: true, errorMessage: translate('app.create_pin_code.error.message.same.digits.requirement') };
  }

  if (!/^\d{4}$/.test(pin)) {
    return { isInvalid: true, errorMessage: translate('app.create_pin_code.error.message.four.digit.requirement') };
  }

  return { isInvalid: false, errorMessage: null };
};

// debit/credit card expiration date => year validation
export const isValidYear = (year: string | undefined): boolean => {
  if (!year) {
    return false;
  }

  if (year.length > 4 || year.length < 2) {
    return false;
  }

  if (year.length === 3) {
    return false;
  }

  const currentYear = new Date().getFullYear();
  const inputYear = parseInt(year.length > 2 ? year : `20${year}`);
  return !isNaN(inputYear) && inputYear >= currentYear;
};

export const setCssColorVariables = (primary: string | undefined, secondary: string | undefined) => {
  const root: HTMLElement | null = document.querySelector(':root');

  if (primary) {
    root?.style.setProperty('--primary', primary);
    root?.style.setProperty('--primary-gradient', primary);
  }

  if (secondary) {
    root?.style.setProperty('--secondary', secondary);
  }
};

export const setConfigurationData = (config: Config | undefined) => {
  if (!config) {
    console.log('Configuration is missing!');
    return;
  }
  LocalStorage.setItem(CONFIG_KEY, config);
  setCssColorVariables(config.colorPrimary, config.colorSecondary);
  document.title = config.displayName ?? '';
};

export const handleAndMonitorError = (message: string) => {
  console.log(message);
  Bugsnag.notify(new Error(message));
};

export const displayAmount = (value: number): string => {
  return value ? value.toFixed(2) : '0.00';
};

export const displayData = (value?: number, currency?: string): string | null => {
  if (!value || !currency) {
    return null;
  }
  return `${CURRENCY_SYMBOL[currency]} ${displayAmount(value)}`;
};
