import { EvseStatus } from 'src/models/evse';

export function isEvseAvailable(hardwareStatus: string): boolean {
  return ['available', 'preparing'].includes(hardwareStatus);
}

export function hasEvseActiveSessionRunnedHere(hardwareStatus: string, hasAvailableSession: boolean): boolean {
  return (
    ['charging', 'occupied', 'suspendedEVSE', 'suspendedEV', 'finishing'].includes(hardwareStatus) &&
    hasAvailableSession
  );
}

export const isEvseOutOfOrder = (status: EvseStatus): boolean => {
  return status === EvseStatus.outOfOrder;
};
