import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../../../components/Loader';
import { ENDPOINT_URL } from '../../../constants/endpoints';
import { handleAndMonitorError } from '../../../helpers';
import useChargePointData from '../../../hooks/useChargePointData';
import useNavigationHandler from '../../../hooks/useNavigationHandler';
import usePayment from '../../../hooks/usePayment/index';
import useSession from '../../../hooks/useSession';
import { StopSessionMode } from '../../../models/config';
import type { Payment } from '../../../models/payment';
import { SessionStatus } from '../../../models/session';
import { ROUTE_NAMES } from '../../../router/route-names';
import { LocalStorage, PAYMENT_KEY } from '../../../storage';

const ConfirmWorldLinePayment = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { getPaymentStatus, getPayment } = usePayment();
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;
  const { chargePoint, evses } = useChargePointData();
  const { startSession } = useSession();
  const { handleGoHomeButton } = useNavigationHandler();
  const finishing = useRef(false);
  const intervalRef = useRef<NodeJS.Timeout>();

  const handleConfirmPayment = useCallback(
    async (paymentData: Required<Omit<Payment, 'sessionId'>>) => {
      const { data } = await getPayment(paymentData.paymentIntentId);

      if (data.payment.stopSessionMode) {
        LocalStorage.setItem(PAYMENT_KEY, {
          ...paymentData,
          stopSessionMode: data.payment.stopSessionMode,
        });
      }

      if (data.payment.stopSessionMode === StopSessionMode.LAST_4_DIGITS) {
        try {
          const response = await startSession({
            chargePointId: paymentData.chargePointId,
            evseId: paymentData.evseId,
            pin: '', // It'll be set from the backend
            paymentIntentId: paymentData.paymentIntentId,
            depositAmount: paymentData.amount,
            currency: paymentData.currency,
            connectorId: paymentData.connectorId,
          });

          const sessionId = response?.data?.sessionId;
          const pin = response?.data?.pin;

          if (sessionId && pin) {
            navigate(`../${ROUTE_NAMES.chargingSession}/${sessionId}`, {
              state: {
                pin,
                sessionId,
              },
              replace: true,
            });
          }
        } catch (e) {
          const errMsg = JSON.parse(e?.response?.data?.error)?.message;
          handleAndMonitorError(`Error while trying to startSession: ${e}, ${errMsg}`);
          navigate(`../${ROUTE_NAMES.chargingSessionFailed}`, {
            state: {
              status: SessionStatus.failed,
              title: JSON.parse(e.response.data.error).localeTitleKey,
              description: JSON.parse(e.response.data.error).localeDescrKey,
            },
            replace: true,
          });
        }
      } else {
        navigate(
          `../${ROUTE_NAMES.choosePaymentMethod}/${paymentData.paymentIntentId}/${ROUTE_NAMES.howToSetPinCode}`,
          {
            state: {
              paymentIntentId: paymentData.paymentIntentId,
            },
          }
        );
      }
    },
    [navigate, startSession, getPayment]
  );

  const handleRejectPayment = useCallback(() => {
    if (payment && chargePoint && evses) {
      LocalStorage.removeItem(PAYMENT_KEY);

      const slicePoint = window.location.href.indexOf('/confirm-payment');
      const newUrl = window.location.href.slice(0, slicePoint);
      const returnUrl = `${newUrl}/evses`;
      window.location.replace(returnUrl);
    }
  }, [chargePoint, evses, payment]);

  const fetchPayment = useCallback(async () => {
    if (finishing.current || !payment?.paymentIntentId) return;
    try {
      const { data } = await getPaymentStatus(payment.paymentIntentId);

      if (data.status) {
        if (data.status === 'AUTHORIZED') {
          finishing.current = true;
          await handleConfirmPayment(payment as Required<Payment>); // The casting of payment's type is secured by the checks above since only payment.paymentIntentId is optional
        } else if (data.status !== 'CREATED') {
          finishing.current = true;
          handleRejectPayment();
        }
      }
    } catch (e) {
      finishing.current = true;

      handleAndMonitorError(
        `Error while trying to get WL order status ${ENDPOINT_URL.getPaymentStatus(payment.paymentIntentId)}: ${e?.response?.data?.error}`
      );
      setErrorMessage(`${e?.response?.data?.error}`);
      handleRejectPayment();
    }
  }, [payment, getPaymentStatus, handleConfirmPayment, handleRejectPayment]);

  useEffect(() => {
    if (!payment || !chargePoint || !evses) {
      return;
    }
    if (!payment.paymentIntentId) {
      handleGoHomeButton();
      return;
    }

    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchPayment, 2000);
    }
  }, [payment, fetchPayment, chargePoint, evses, handleGoHomeButton]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Perform actions before the component unloads
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <section>
      {!errorMessage ? (
        <Loader />
      ) : (
        <section className="general-error">{errorMessage && <div>{errorMessage}</div>}</section>
      )}
    </section>
  );
};
export default ConfirmWorldLinePayment;
