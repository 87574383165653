import type { FC } from 'react';
import React from 'react';

import DetailItem from './DetailItem';
import { displayData } from '../../helpers';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import type { DetailsSectionProps } from '../../models/evse';

type TariffSelectorProps = Omit<DetailsSectionProps, 'tenant' | 'chargePointId'>;

const DurationEnergyTariff: FC<TariffSelectorProps> = props => {
  const { t } = useAppTranslation();
  const {
    tariff: {
      pricing: {
        connectionFee,
        connectionFeeMinimumSessionDuration,
        connectionFeeMinimumSessionEnergy,
        pricePerPeriod,
        pricePeriodInMinutes,
        durationFeeGracePeriod,
        idleFeePerMinute,
        idleFeeGracePeriodMinutes,
        minPrice,
        pricePerKwh,
      },
      restrictions: { adHocPreAuthorizeAmount: deposit },
      currency,
    },
  } = props;

  return (
    <>
      {deposit ? (
        <DetailItem translations={[t('app.tariff.pre_auth_amount')]} tariffInfo={[displayData(deposit, currency)]} />
      ) : null}
      {pricePerKwh ? (
        <DetailItem translations={[t('app.tariff.price_per_kwh')]} tariffInfo={[displayData(pricePerKwh, currency)]} />
      ) : null}
      {minPrice ? (
        <DetailItem
          separator
          translations={[t('app.tariff.min_price')]}
          tariffInfo={[displayData(minPrice, currency)]}
        />
      ) : null}
      {connectionFee && connectionFee > 0 ? (
        <DetailItem
          translations={[
            t('app.tariff.connection_fee'),
            t('app.tariff.connection_fee_min_session_duration'),
            t('app.tariff.connection_fee_min_session_energy'),
          ]}
          tariffInfo={[
            displayData(connectionFee, currency),
            `${connectionFeeMinimumSessionDuration} mins`,
            `${connectionFeeMinimumSessionEnergy} kWh`,
          ]}
        />
      ) : null}
      {pricePerPeriod && pricePerPeriod > 0 ? (
        <DetailItem
          // TODO: Figure out why we need space for these translations
          separator
          translations={[
            t('app.tariff.fee_per_time_period'),
            t('app.tariff.duration_fee_time_period'),
            t('app.tariff.duration_fee_grace_period'),
          ]}
          tariffInfo={[
            displayData(pricePerPeriod, currency),
            `${pricePeriodInMinutes} mins`,
            `${durationFeeGracePeriod} mins`,
          ]}
        />
      ) : null}
      {idleFeeGracePeriodMinutes && idleFeeGracePeriodMinutes > 0 ? (
        <DetailItem
          translations={[t('app.tariff.idle_fee_per_minute'), t('app.tariff.idle_fee_grace_period')]}
          tariffInfo={[displayData(idleFeePerMinute, currency), `${idleFeeGracePeriodMinutes} min`]}
        />
      ) : null}
    </>
  );
};

export default DurationEnergyTariff;
