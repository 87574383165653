import React from 'react';

import styles from './styles.module.css';
import { FA_ICONS } from '../../../constants';
import Card from '../../Card';
import Image from '../../Image';

interface ChargePointCardProps {
  locationName: string | undefined;
  address: string | undefined;
  className?: string;
}

const ChargePointCard = ({ locationName, address, className }: ChargePointCardProps) => {
  return (
    <Card className={`${styles.container} ${className}`}>
      <section>
        <Image src={FA_ICONS.faLocationDot} />
      </section>
      <section className={styles.details}>
        <span className={styles.name}>{locationName}</span>
        <span className={styles.location}>{address}</span>
      </section>
    </Card>
  );
};

export default ChargePointCard;
