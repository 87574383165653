import type { FC } from 'react';
import React from 'react';

import DetailItem from './DetailItem';
import { displayData } from '../../helpers';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import type { DetailsSectionProps } from '../../models/evse';

type TariffSelectorProps = Omit<DetailsSectionProps, 'tenant' | 'chargePointId' | 'noShowFixedTariff'> & {
  noShowFixedTariff?: boolean;
};

const StandardTODTariff: FC<TariffSelectorProps> = props => {
  const { t } = useAppTranslation();
  const {
    noShowFixedTariff,
    tariff: {
      pricing: {
        connectionFee,
        connectionFeeMinimumSessionDuration,
        connectionFeeMinimumSessionEnergy,
        pricePerPeriod,
        pricePeriodInMinutes,
        durationFeeGracePeriod,
        idleFeePerMinute,
        idlePricingPeriodInMinutes,
        idleFeeGracePeriodMinutes,
        minPrice,
      },
      restrictions: { adHocPreAuthorizeAmount: deposit },
      currency,
    },
  } = props;
  return (
    <>
      {deposit && deposit > 0 && !noShowFixedTariff ? (
        <DetailItem
          separator
          translations={[t('app.tariff.pre_auth_amount')]}
          tariffInfo={[displayData(deposit, currency)]}
        />
      ) : null}
      {minPrice && !noShowFixedTariff ? (
        <DetailItem
          separator
          translations={[t('app.tariff.min_price')]}
          tariffInfo={[displayData(minPrice, currency)]}
        />
      ) : null}
      {connectionFee > 0 && connectionFee > 0 ? (
        <DetailItem
          separator
          translations={[t('app.tariff.connection_fee')]}
          tariffInfo={[displayData(connectionFee, currency)]}
        />
      ) : null}
      {connectionFeeMinimumSessionDuration && connectionFeeMinimumSessionDuration > 0 ? (
        <DetailItem
          translations={[t('app.tariff.connection_fee_min_session_duration')]}
          tariffInfo={[`${connectionFeeMinimumSessionDuration} mins`]} />) : null}

      {connectionFeeMinimumSessionEnergy && connectionFeeMinimumSessionEnergy > 0 ?
        (<DetailItem
          translations={[t('app.tariff.connection_fee_min_session_energy')]}
          tariffInfo={[`${connectionFeeMinimumSessionEnergy} kWh`]}
        />) : null}

      {pricePerPeriod && pricePerPeriod > 0 ? (
        <DetailItem
          separator
          translations={[t('app.tariff.fee_per_time_period')]}
          tariffInfo={[displayData(pricePerPeriod, currency)]}
        />
      ) : null}
      {pricePeriodInMinutes && pricePeriodInMinutes > 0 ? (
        <DetailItem
          translations={[t('app.tariff.duration_fee_time_period')]}
          tariffInfo={[`${pricePeriodInMinutes} mins`]}
        />
      ) : null}
      {durationFeeGracePeriod && durationFeeGracePeriod > 0 ? (
        <DetailItem
          translations={[t('app.tariff.duration_fee_grace_period')]}
          tariffInfo={[`${durationFeeGracePeriod} mins`]}
        />
      ) : null}
      {idleFeePerMinute && idleFeePerMinute > 0 ? (
        <DetailItem
          separator
          translations={[t('app.tariff.idle_fee_per_minute')]}
          tariffInfo={[displayData(idleFeePerMinute, currency)]}
        />
      ) : null}
      {idlePricingPeriodInMinutes && idlePricingPeriodInMinutes > 0 ? (
        <DetailItem
          translations={[t('app.tariff.idle_fee_time_period')]}
          tariffInfo={[`${idlePricingPeriodInMinutes} min`]}
        />
      ) : null}
      {idleFeeGracePeriodMinutes && idleFeeGracePeriodMinutes > 0 ? (
        <DetailItem
          translations={[t('app.tariff.idle_fee_grace_period')]}
          tariffInfo={[`${idleFeeGracePeriodMinutes} min`]}
        />
      ) : null}
    </>
  );
};

export default StandardTODTariff;
