import React from 'react';

import LanguageFlag from '../LanguageFlag';
import LanguageLabel from '../LanguageLabel';
import styles from './styles.module.css';

interface LanguageProps {
  code: string;
  onClick: (code: string) => void;
}

const Language = ({ code, onClick }: LanguageProps) => {
  return (
    <li onClick={() => onClick(code)} className={`blue-card ${styles.card}`}>
      <LanguageFlag code={code} />
      <span className={styles.label}>
        <LanguageLabel code={code} />
      </span>
    </li>
  );
};

export default Language;
