import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { defaultLanguageCode, TENANT_SLANG_SUFFIX } from '.';
import { LANGUAGE_KEY, LocalStorage } from '../storage';

export default i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    partialBundledLanguages: true,
    lng: (LocalStorage.getItem(LANGUAGE_KEY) as string | undefined) || defaultLanguageCode,
    fallbackLng: (code: string) => {
      const fallbackChain: string[] = [code];

      if (code.includes(TENANT_SLANG_SUFFIX)) {
        const normalizedTenantCode = code.split(TENANT_SLANG_SUFFIX)[0];
        if (!fallbackChain.includes(normalizedTenantCode)) {
          fallbackChain.push(normalizedTenantCode);
        }
      }

      if (!fallbackChain.includes(defaultLanguageCode)) {
        fallbackChain.push(defaultLanguageCode);
      }

      const normalizedChain = fallbackChain.map(lng => lng.toLocaleLowerCase());
      return normalizedChain;
    },
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

require('./locales/index.ts');
