import React, { DOMAttributes } from 'react';

interface IconProps {
  src: string;
  className?: string;
  onClick?: DOMAttributes<HTMLImageElement>['onClick'];
}

const Image = ({ src, className, onClick }: IconProps) => {
  return <img src={src} alt="" onClick={onClick} className={className} />;
};

export default Image;
