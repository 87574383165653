import React, { useEffect, useRef, useState } from 'react';

import Button, { ButtonType } from '../Button';
import Image from '../Image';
import StatusIndicator from '../StatusIndicator';
import DetailsSection from './DetailsSection';
import styles from './styles.module.css';
import { FA_ICONS } from '../../constants';
import { CONNECTOR, CONNECTOR_NAME } from '../../constants/connector';
import { CURRENCY_SYMBOL } from '../../constants/currencySymbol';
import { DisplayMode } from '../../constants/display-mode';
import { REFRESH_INTERVAL } from '../../constants/intervals';
import { convertWattToKilowatt } from '../../helpers';
import { isEvseOutOfOrder } from '../../helpers/evse-statuses';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useChargePointData from '../../hooks/useChargePointData';
import useConfig from '../../hooks/useConfig';
import useSession from '../../hooks/useSession';
import type { EvseModel as EvseModel } from '../../models/evse';
import { HardwareStatus } from '../../models/evse';

interface EvseProps {
  tenant: string;
  chargePointId: number;
  evse: EvseModel;
  numberOfEvses: number;
  locationName?: string;
  address?: string;
}

const Evse = ({ tenant, chargePointId, evse, numberOfEvses, locationName, address }: EvseProps) => {
  const { checkSessionAvailability } = useSession();
  const { dataFetch } = useChargePointData();
  const { t } = useAppTranslation();
  const [showDetailsSection, setShowDetailsSection] = useState(numberOfEvses === 1);
  const { displayMode } = useConfig();
  const [socPercent, setSocPercent] = useState<number | null>(null);
  const [startedAt, setStartedAt] = useState<string | undefined>();
  const [sessionId, setSessionId] = useState<number | undefined>();
  const [hardwareStatus, setHardwareStatus] = useState<HardwareStatus>(evse.hardwareStatus);
  const interval = useRef<NodeJS.Timeout | null>(null);

  const fetchData = async () => {
    try {
      const availableSession = await checkSessionAvailability(chargePointId, evse.id);
      if (!startedAt && availableSession.data?.startedAt) {
        setStartedAt(availableSession.data.startedAt);
      }
      if (availableSession.data?.socPercent) {
        setSocPercent(availableSession.data.socPercent);
      }
      if (availableSession.data?.hardwareStatus) {
        setHardwareStatus(availableSession.data.hardwareStatus);
      }
      setSessionId(availableSession.data?.realSessionId);
    } catch (e) {
      if (e.response.status === 404) {
        await dataFetch();
        setStartedAt(undefined);
        setSocPercent(null);
        setHardwareStatus(evse.hardwareStatus);
      }
    }
  };

  useEffect(() => {
    if (!interval.current) {
      fetchData();

      interval.current = setInterval(() => {
        fetchData();
      }, REFRESH_INTERVAL);
    }
    return () => {
      clearInterval(interval.current as NodeJS.Timeout);
    };
  }, []);

  const tariffData = (): string => {
    return `${CURRENCY_SYMBOL[evse.tariff.currency]} ${evse.tariff.pricing.pricePerKwh.toFixed(2)} per kWh`;
  };

  const tariffDayData = (): string | null => {
    if (!evse.tariff.pricing?.dayPricePerKwh) return null;
    return `${CURRENCY_SYMBOL[evse.tariff.currency]} ${evse.tariff.pricing?.dayPricePerKwh.toFixed(2)} per kWh`;
  };

  const tariffNightData = (): string | null => {
    if (!evse.tariff.pricing?.nightPricePerKwh) return null;
    return `${CURRENCY_SYMBOL[evse.tariff.currency]} ${evse.tariff.pricing?.nightPricePerKwh.toFixed(2)} per kWh`;
  };

  const getConnectors = (): string[] => {
    return evse.connectors.map(connector => connector.type);
  };

  const handleClick = () => {
    setShowDetailsSection(previousState => !previousState);
  };

  return (
    <>
      <section className={styles.container} onClick={handleClick}>
        {displayMode !== DisplayMode.ChargePoint && (
          <section className={`blue-card ${styles.addressSection}`}>
            <aside>
              <Image src={FA_ICONS.faLocationDot} />
            </aside>
            <section className={`${styles.data}`}>
              <span className={styles.locationName}>{locationName}</span>
              <span>{address}</span>
            </section>
          </section>
        )}
        <section className={styles.detailsRow}>
          <section className={`blue-card ${styles.details}`}>
            <aside>
              <Image src={FA_ICONS.faLocation} />
            </aside>
            <section className={styles.data}>
              <span className={`bold ${styles.energy}`}>{`${convertWattToKilowatt(
                evse.powerOptions.maxPower
              )} kW ${evse.currentType.toUpperCase()}`}</span>
              <span className={styles.id}>ID: {evse.physicalReference}</span>
              {evse.label ? <span className={styles.id}>{`(${evse.label})`}</span> : null}
            </section>
          </section>
          <section className={`blue-card ${styles.connectorContainer}`}>
            {getConnectors().map(type => {
              return (
                <section key={type} className={styles.connector}>
                  <Image src={CONNECTOR[type]} />
                  <span className={styles.connectorType}>{CONNECTOR_NAME[type]}</span>
                </section>
              );
            })}
          </section>
        </section>
        <StatusIndicator
          hardwareStatus={hardwareStatus}
          status={evse.status}
          socPercent={socPercent}
          startedAt={startedAt}
          isBottomRow={false}
        />
        {evse.tariff.pricing.pricePerKwh ? (
          <section className={`blue-card ${styles.tariffRow}`}>
            <section className={styles.tariffRow}>
              <Image src={FA_ICONS.faLightMedal} />
              <section className={styles.tariffData}>{tariffData()}</section>
            </section>

            {!showDetailsSection && (
              <section className={styles.action}>
                <Button
                  label={
                    evse.hardwareStatus === HardwareStatus.available
                      ? t('app.select_evse.select.button')
                      : t('app.select_evse.view.button')
                  }
                  type={ButtonType.primary}
                  className={styles.evseAction}
                  disabled={isEvseOutOfOrder(evse.status)}
                />
              </section>
            )}
          </section>
        ) : null}
        {evse.tariff.pricing.nightPricePerKwh && evse.tariff.pricing.dayPricePerKwh ? (
          <section className={styles.detailsRow}>
            <section className={`blue-card ${styles.tariffRow}`}>
              <section className={showDetailsSection ? styles.tariffRow : styles.detailsCol}>
                <section className={styles.tariffRow}>
                  <Image src={FA_ICONS.faSun} />
                  <section className={styles.tariffData}>{tariffDayData()}</section>
                </section>
                <section className={styles.tariffRow}>
                  <Image src={FA_ICONS.faMoon} />
                  <section className={styles.tariffData}>{tariffNightData()}</section>
                </section>
              </section>
              {!showDetailsSection && (
                <section className={styles.action}>
                  <Button
                    label={
                      evse.hardwareStatus === HardwareStatus.available
                        ? t('app.select_evse.select.button')
                        : t('app.select_evse.view.button')
                    }
                    type={ButtonType.primary}
                    className={styles.evseAction}
                    disabled={isEvseOutOfOrder(evse.status)}
                  />
                </section>
              )}
            </section>
          </section>
        ) : null}
      </section>
      {/* Hidden section with Details which shows when Select/View buttons are clicked */}
      {showDetailsSection && (
        <DetailsSection
          activeSession={!!startedAt}
          sessionId={sessionId}
          {...evse}
          tenant={tenant}
          chargePointId={chargePointId}
        />
      )}
    </>
  );
};

export default Evse;
