import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components

// Types

import styles from './styles.module.css';
import Button, { ButtonType } from '../../components/Button';
import Header from '../../components/Header';
import Input from '../../components/Input';
import { DisplayMode } from '../../constants/display-mode';
import { ENDPOINT_URL } from '../../constants/endpoints';
import { checkLast4, handleAndMonitorError } from '../../helpers';
import { buildTenantUrl, checkPathnameIncludes } from '../../helpers/routing';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useConfig from '../../hooks/useConfig';
import useSession from '../../hooks/useSession';
import type { Payment } from '../../models/payment';
import { ROUTE_NAMES } from '../../router/route-names';
import { LocalStorage, PAYMENT_KEY } from '../../storage';

const EnterLast4 = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { name, displayMode } = useConfig();
  const [cardDigits, setCardDigits] = useState<string>('');
  const [cardDigitsError, setCardDigitsError] = useState<string | null>(location?.state?.error);
  const payment = LocalStorage.getItem(PAYMENT_KEY) as Payment | undefined;
  const { getSession } = useSession();
  const [disableContinue, setDisableContinue] = useState(false);

  const onNext = async () => {
    setDisableContinue(true);

    const result = checkLast4(cardDigits, t);

    if (result.isInvalid) {
      setCardDigitsError(result.errorMessage);

      setDisableContinue(false);

      return;
    }

    if (!payment) {
      setCardDigitsError('No available payment');

      setDisableContinue(false);

      return;
    }

    if (location?.state?.summary) {
      navigate(`../${ROUTE_NAMES.chargingSession}/${payment?.sessionId}/${ROUTE_NAMES.sessionSummary}`, {
        state: {
          pin: cardDigits,
        },
        replace: true,
      });

      setDisableContinue(false);

      return;
    }

    try {
      await getSession(payment.chargePointId, payment.evseId, payment?.sessionId ?? '', cardDigits);

      navigate(`../${ROUTE_NAMES.chargingSession}/${payment?.sessionId}`, {
        state: {
          pin: cardDigits,
          enterPin: true,
          sessionId: payment?.sessionId,
        },
        replace: true,
      });
    } catch (e) {
      handleAndMonitorError(
        `Error while trying to get session data from ${ENDPOINT_URL.session(
          payment.chargePointId,
          payment.evseId,
          payment?.sessionId,
          cardDigits
        )}: ${e}`
      );
      setCardDigitsError('Incorrect last 4 digits.');
    } finally {
      setDisableContinue(false);
    }
  };

  const onBack = () => {
    if (displayMode === DisplayMode.Search) {
      navigate(`../${ROUTE_NAMES.selectEvse}`);
    } else {
      if (checkPathnameIncludes('tenant')) {
        navigate(`../${ROUTE_NAMES.selectEvse}`);
      } else {
        navigate(
          buildTenantUrl({
            pathPrefix: '..',
            tenant: name,
            routeName: ROUTE_NAMES.selectEvse,
          })
        );
      }
    }
  };

  return (
    <section className="page-container">
      <Header showBackButton label={t('app.last_4_digits.title')} onClick={onBack} />
      <section className={styles.container}>
        <Input
          label={t('app.re_enter_card_details.input.card.details.label')}
          type="number"
          error={cardDigitsError}
          setValue={setCardDigits}
        />
      </section>
      <Button
        label={t('app.last_4_digits.submit.button')}
        type={ButtonType.primary}
        onClick={onNext}
        disabled={disableContinue}
      />
    </section>
  );
};

export default EnterLast4;
