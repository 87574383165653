import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import styles from './styles.module.css';
import TariffSelector from '../../components/Evse/DetailsSection/TariffSelector';
import Header from '../../components/Header';
import { CHART_COLORS } from '../../constants';
import { CURRENCY_SYMBOL } from '../../constants/currencySymbol';
import {
  getMaxYAxisValue,
  createGetFillColor,
  transformPricingDataToTariffData,
} from '../../helpers/forecast-calculations';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import useChargePointData from '../../hooks/useChargePointData';
import type { TariffData } from '../../models/evse';

const PriceForecast: React.FC = () => {
  const { evses, dataFetch } = useChargePointData();
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state?.evseProps && evses.length === 0) {
      dataFetch();
    }
  }, [location.state?.evseProps, evses.length]);

  const tariff = location.state?.evseProps?.tariff ?? evses[0]?.tariff;
  const data = transformPricingDataToTariffData(tariff?.pricing);
  const onBack = () => navigate(-1);
  const currencySymbol = CURRENCY_SYMBOL[tariff?.currency];

  const renderCell = (value: number | undefined) => {
    return value !== undefined ? (
      <td className={styles.tableCell}>
        {currencySymbol} {value.toFixed(2)}
      </td>
    ) : null;
  };

  const renderLegendItem = (
    data: TariffData[],
    key: keyof TariffData,
    color: string,
    translationKey: string,
    letter: string,
    lockPrice?: boolean
  ) => {
    return data[0]?.[key] !== undefined ? (
      <div className={styles.legendItem}>
        <div className={styles.legendDot} style={{ backgroundColor: color }} />
        <span>
          ({letter}) {t(translationKey)}
          {lockPrice && '*'}
        </span>
      </div>
    ) : null;
  };

  // Add a gray color for bars in the second half of the chart
  const GRAY_COLOR = '#888888';

  const renderBar = (data: TariffData[], key: keyof TariffData, color: string, translationKey: string) => {
    if (data[0]?.[key] === undefined) return null;

    // Calculate middle index of the data array
    const middleIndex = Math.floor(data.length / 2);

    // Get the fill color function from our utils
    const getFillColor = createGetFillColor(middleIndex, GRAY_COLOR, color);

    return (
      <Bar
        dataKey={key}
        name={t(translationKey)}
        maxBarSize={40}
        fill={color}
        fillOpacity={1}
        isAnimationActive={false}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={getFillColor(entry, index)} />
        ))}
      </Bar>
    );
  };

  const renderTooltip = () => {
    return (
      <Tooltip
        wrapperStyle={{ zIndex: 1000 }}
        contentStyle={{ backgroundColor: 'white' }}
        // eslint-disable-next-line react/no-unstable-nested-components
        content={({ active, payload }) => {
          if (active && payload && payload.length) {
            const data = payload[0].payload as TariffData;
            return (
              <div className={styles.tooltipContainer}>
                <div className={styles.tooltipTime}>
                  {data.hour} - {data.hourEnd}
                </div>
                {payload.map((entry, index) => (
                  <div key={`tooltip-${index}`} className={styles.tooltipItem}>
                    <span style={{ color: entry.color, marginRight: 5 }}>{entry.name}: </span>
                    <span style={{ color: entry.color }}>
                      {currencySymbol} {Number(entry.value).toFixed(2)}
                    </span>
                  </div>
                ))}
              </div>
            );
          }
          return null;
        }}
      />
    );
  };

  if (!data.length || !tariff) {
    return null;
  }

  const showFrozenTariff =
    tariff.pricing?.lockDurationPriceOnSessionStart ||
    tariff.pricing?.lockEnergyPriceOnSessionStart ||
    tariff.pricing?.lockIdlePriceOnSessionStart;

  const showVariableTariffComponents =
    tariff.pricing?.connectionFeeMinimumSessionDuration > 0 ||
    tariff.pricing?.connectionFeeMinimumSessionEnergy > 0 ||
    tariff.pricing?.idleFeePerMinute > 0 ||
    tariff.pricing?.durationFeeGracePeriod > 0 ||
    tariff.pricing?.idlePricingPeriodInMinutes > 0 ||
    tariff.pricing?.idleFeeGracePeriodMinutes > 0;

  return (
    <section className={styles.container}>
      <Header showBackButton label={t('app.price.forecast.title')} onClick={onBack} />

      <p className={styles.description}>
        {t('app.price.forecast.description', {
          count: tariff.pricing?.timePeriods?.length,
        })}
      </p>
      {showVariableTariffComponents ? (
        <section className={styles.tariff}>
          <h2 className={styles.sectionTitle}>{t('app.price.forecast.fixed_tariff_components')}</h2>
          <TariffSelector {...{ tariff, noShowFixedTariff: true }} />
        </section>
      ) : null}
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>{t('app.price.forecast.variable_tariff_components')}</h2>
        <div className={styles.chartContainer}>
          <ResponsiveContainer width="100%" minWidth={400} height={300}>
            <BarChart data={data} margin={{ top: 10, right: 0, left: -20, bottom: 0 }}>
              <CartesianGrid vertical={false} strokeDasharray="0" stroke={CHART_COLORS.GRID} />
              <XAxis xAxisId={0} dataKey="hour" interval={0} tick={{ fontSize: 12 }} />
              <XAxis
                xAxisId={1}
                dy={-15}
                dx={0}
                label={{ value: '', angle: 0, position: 'bottom' }}
                dataKey="hourEnd"
                tickLine={false}
                tick={{ fontSize: 12 }}
                axisLine={false}
              />
              <YAxis
                domain={[0, getMaxYAxisValue(data)]}
                tickFormatter={value => `${currencySymbol}${value}`}
                tick={{ fontSize: 12 }}
                // ticks={yAxisTicks}
              />
              {renderTooltip()}

              {renderBar(data, 'connectionFee', CHART_COLORS.CONNECTION_FEE, 'app.tariff.connection_fee')}
              {renderBar(data, 'energyFee', CHART_COLORS.ENERGY_FEE, 'app.tariff.energy_fee_per_kwh')}
              {renderBar(data, 'durationFee', CHART_COLORS.DURATION_FEE, 'app.tariff.duration_fee.title')}
              {renderBar(data, 'idleFee', CHART_COLORS.IDLE_FEE, 'app.tariff.idle_fee.title')}
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className={styles.legendTitle}>{t('app.price.forecast.legend')}</div>
        <div className={styles.legendWrapper}>
          <div className={styles.legendContainer}>
            {renderLegendItem(data, 'connectionFee', CHART_COLORS.CONNECTION_FEE, 'app.tariff.connection_fee', 'A')}
            {renderLegendItem(
              data,
              'energyFee',
              CHART_COLORS.ENERGY_FEE,
              'app.tariff.energy_fee_per_kwh',
              'B',
              tariff.pricing?.lockEnergyPriceOnSessionStart
            )}
            {renderLegendItem(
              data,
              'durationFee',
              CHART_COLORS.DURATION_FEE,
              'app.tariff.duration_fee.title',
              'C',
              tariff.pricing?.lockDurationPriceOnSessionStart
            )}
            {renderLegendItem(
              data,
              'idleFee',
              CHART_COLORS.IDLE_FEE,
              'app.tariff.idle_fee.title',
              'D',
              tariff.pricing?.lockIdlePriceOnSessionStart
            )}
            {/* {renderLegendItem(data, 'price', CHART_COLORS.IDLE_FEE, 'app.price.forecast.markup.title', 'A', false)} */}
          </div>
        </div>
        {showFrozenTariff && <div className={styles.legendNote}>(*) {t('app.price.forecast.frozen_tariff')}</div>}

        <table className={styles.table}>
          <thead>
            <tr>
              <th>{t('app.price.forecast.hour')}</th>
              {data[0].connectionFee !== undefined && <th className={styles.legendCell}>A</th>}
              {data[0].energyFee !== undefined && <th className={styles.legendCell}>B</th>}
              {data[0].durationFee !== undefined && <th className={styles.legendCell}>C</th>}
              {data[0].idleFee !== undefined && <th className={styles.legendCell}>D</th>}
              {/* {data[0].price !== undefined && <th>A</th>} */}
            </tr>
          </thead>
          <tbody>
            {data.map(row => (
              <tr key={row.hour}>
                <td>
                  <span className={styles.legendCell}>
                    {row.hour}
                    <br />
                    {row.hourEnd}
                  </span>
                </td>
                {renderCell(row.connectionFee)}
                {renderCell(row.energyFee)}
                {renderCell(row.durationFee)}
                {renderCell(row.idleFee)}
                {/* {renderCell(row.price)} */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default PriceForecast;
