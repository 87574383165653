import moment from 'moment';

/**
 * When the client has isMultiTimezonesEnabled feature flag enabled, the backend returns startedAtLocal and stoppedAtLocal.
 * In case isMultiTimezonesEnabled is false, we're using the default startedAt/stoppedAt and formatting with the timezone of the user's browser.
 * @param dateTimeLocal - The date time in local timezone of the evse
 * @param dateTime- The date time in UTC timezone
 * @param format - The format in which the date time should be formatted, default is 'h:mm A YYYY-MM-DD'
 */
export function formatDate(dateTime: string, dateTimeLocal?: string, format?: string) {
  if ((!dateTimeLocal && !dateTime) || (isDateInvalid(dateTimeLocal) && isDateInvalid(dateTime))) {
    return '';
  }
  if (!format || isFormatInvalid(format)) {
    // Default format that was used by all clients initially
    format = 'h:mm A YYYY-MM-DD';
  }

  const formattedDate = moment(new Date(getProperDate(dateTime, dateTimeLocal))).format(format);
  if (formattedDate === 'Invalid date') {
    return '';
  }

  return formattedDate;
}

export function formatElapsedTime(milliseconds: number): string {
  const seconds = Math.floor(milliseconds / 1000) % 60;
  const minutes = Math.floor(milliseconds / 1000 / 60) % 60;
  const hours = Math.floor(milliseconds / 1000 / 60 / 60) % 24;
  if (hours > 0) return `${hours}h ${minutes}m`;
  if (minutes > 0) return `${minutes}m ${seconds}s`;
  return `${seconds}s`;
}

function isFormatInvalid(format: string) {
  return moment(new Date()).format(format) === 'Invalid date';
}

function isDateInvalid(formattedDate?: string): boolean {
  if (!formattedDate) {
    return true;
  }
  const date = new Date(formattedDate);
  // If the date object is invalid it will return 'NaN' on getTime() and NaN is never equal to itself
  return date.getTime() !== date.getTime();
}

function getProperDate(dateTime: string, dateTimeLocal?: string): string {
  if (dateTimeLocal && !isDateInvalid(dateTimeLocal)) {
    return dateTimeLocal;
  } else {
    return dateTime;
  }
}
