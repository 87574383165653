import React from 'react';

import styles from './styles.module.css';
import { FA_ICONS } from '../../constants';
import { capitalizeFirstLetter } from '../../helpers';
import { joinClass } from '../../helpers/joinClass';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import type { EvseModel } from '../../models/evse';
import { EvseStatus, HardwareStatus } from '../../models/evse';
import Card from '../Card';
import ChargingTime from '../ChargingTime';
import Image from '../Image';

interface StatusIndicatorProps {
  hardwareStatus: EvseModel['hardwareStatus'];
  status: EvseModel['status'];
  numberOfEvses?: number;
  isBottomRow?: boolean;
  socPercent?: number | null;
  startedAt?: string;
}

const StatusIndicator = ({
  hardwareStatus,
  status,
  numberOfEvses,
  isBottomRow = true,
  socPercent,
  startedAt,
}: StatusIndicatorProps) => {
  const { t } = useAppTranslation();
  let icon: string | undefined;
  let text: string | undefined;
  let textClass = styles.bold;

  if (status === EvseStatus.outOfOrder) {
    icon = FA_ICONS.faCircleXmark;
    text = 'app.charge_point.status_out_of_order';
    textClass = joinClass(textClass, styles.red);
  } else {
    switch (hardwareStatus) {
      case HardwareStatus.available:
        icon = FA_ICONS.faCircleCheckLarge;
        text = 'app.charge_point.status_available';
        break;
      case HardwareStatus.charging:
        text = 'app.charge_point.status_charging';
        break;
      case HardwareStatus.preparing:
        icon = FA_ICONS.faChargingBattery;
        text = 'app.charge_point.status_preparing';
        break;
      case HardwareStatus.faulted:
        text = 'app.charge_point.status_faulted';
        break;
      case HardwareStatus.reserved:
        text = 'app.charge_point.status_reserved';
        break;
      case HardwareStatus.suspendedEV:
        text = 'app.charge_point.status_suspended_ev';
        break;
      case HardwareStatus.suspendedEVSE:
        text = 'app.charge_point.status_suspended_evse';
        break;
      case HardwareStatus.finishing:
        text = 'app.charge_point.status_finishing';
        break;
      case HardwareStatus.occupied:
        text = 'app.charge_point.status_occupied';
        break;
      case HardwareStatus.unavailable:
        text = 'app.charge_point.status_unavailable';
        break;
      default:
        text = undefined;
        icon = undefined;
    }
  }

  if (numberOfEvses === 0) {
    icon = FA_ICONS.faCircleXmark;
    textClass = joinClass(textClass, styles.red);
  }

  const statusTranslation = t(text as HardwareStatus);
  const shouldShowTranslation = statusTranslation.length > 0;

  const getSocPercentIcon = (socPercent: number | null) => {
    if (!socPercent) {
      return null;
    }
    let chargingIcon: string = FA_ICONS.faBatteryQuarterSolid;
    if (socPercent > 25) {
      console.log('FA_ICONS.faBatteryHalfSolid');
      chargingIcon = FA_ICONS.faBatteryHalfSolid;
    }
    if (socPercent > 50) {
      console.log('FA_ICONS.faBatteryThreeQuarterSolid');
      chargingIcon = FA_ICONS.faBatteryThreeQuarterSolid;
    }
    if (socPercent > 94) {
      console.log('FA_ICONS.faBatteryFullSolid');
      chargingIcon = FA_ICONS.faBatteryFullSolid;
    }

    return <Image src={chargingIcon} className={styles.socIcon} />;
  };

  const getStartedAtTimer = (startedAt?: string) => {
    if (!startedAt) {
      return null;
    }
    return <ChargingTime startedAt={startedAt} />;
  };

  return (
    <Card className={`${styles.container} ${isBottomRow ? styles.bottomRow : ''}`}>
      <section className={styles.status}>
        {icon ? <Image src={icon} className={styles.icon} /> : null}
        <span className={textClass}>
          {numberOfEvses === undefined ? null : `${numberOfEvses}`}{' '}
          {shouldShowTranslation ? statusTranslation : capitalizeFirstLetter(status)}
        </span>
      </section>

      {socPercent && startedAt ? (
        <span className={styles.soc}>
          {getSocPercentIcon(socPercent)}
          <span className={textClass}>
            {`${socPercent}%`} {getStartedAtTimer(startedAt)}
          </span>
        </span>
      ) : null}
    </Card>
  );
};

export default StatusIndicator;
