import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.css';
import Header from '../../components/Header';
import { useAppTranslation } from '../../hooks/useAppTranslation';
import { LANGUAGE_KEY, LocalStorage } from '../../storage';

const PricingDetails = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const locale = LocalStorage.getItem(LANGUAGE_KEY) as string | undefined;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const [language, region] = locale?.split('_') ?? [];
  const location = useLocation();
  const additionalInfo = location.state?.description[language ?? 'en'];

  const onBack = () => navigate(-1);

  return (
    <section className={styles.container}>
      <Header showBackButton label={t('app.price_details.title')} onClick={onBack} />
      {additionalInfo ? <section dangerouslySetInnerHTML={{ __html: additionalInfo ?? undefined }} /> : null}
    </section>
  );
};
export default PricingDetails;
